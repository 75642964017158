// ADD_USER
export const addUser = (
	{
		firstname = 'default-first-name',
		lastname = 'default-last-name',
		subscriptionplan = {},
		subscribed = false,
		subscriptionID = 'default-subID',
		createdon = 'default-created-on',
		associatedID = 'default-associatedID',
		email = 'default-email',
		dob = 'default-dob',
		phone = 'default-phone',
		companyname = 'default-companyname',
		address = 'default-address'
	} = {}
) => ({
  	type: 'ADD_USER',
  	user: {
		firstname,
		lastname,
		subscriptionplan,
		subscribed,
		phone,
		subscriptionID,
		createdon,
		associatedID,
		email,
		dob,
		companyname,
		address
	}
});

// EDIT_USER
export const editUser = (user) => ({
  	type: 'EDIT_USER',
  	user: user
});