import React from 'react';
import {Link} from 'react-router-dom';

//If you have 0 stores.

const FirstStoreTile = () =>{
	return(
		<Link to={'/new-store'} className="my-store-tile add-first">
			<img src="/images/icons/plus-icon.svg" alt="plus-icon"/>
			<p>Add your first store</p>
		</Link>
	)
}

export default FirstStoreTile;