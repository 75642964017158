import React from 'react';

const DeleteStoreForm = (props) => {
	const sendToParent = (e) => {
		const command = e.target.value;
		props.callback(command);
	}
	return (
		<div className="new-item-form">
			<h2>Delete Store</h2>
			<hr className="title-underline" />

			<p>Warning, you are about to delete your store. You cannot undo this.</p>
			<p>Are you sure you want to continue?</p>

			<div className="button-wrapper">
				<button className="button dont-delete-store"
					value="cancel"
					onClick={sendToParent}>
					No, I don't want to do that
				</button>
				<button className="button delete-store"
					value="delete"
					onClick={sendToParent}>
					Delete this store
				</button>
			</div>
		</div>
	);
}

export default DeleteStoreForm;









