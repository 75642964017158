// Inventory Reducer
const loadingReducerDefaultState = false;

export default (state = loadingReducerDefaultState, action) => {
	switch (action.type) {
    	case 'SET_LOADING':
      		return action.state;
    	default:
      		return state;
  	}
};