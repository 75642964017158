import React from 'react';
import Papa from 'papaparse';
import {v5 as uuid} from 'uuid';

import {FIREBASE_CONFIG} from '../../../../config/config';

export default class CSVUploadModal extends React.Component{
	constructor(props){
		super(props);

	}

	passToParent = (e) => {
		var file = e.target.files[0];

		Papa.parse(file, {
			header : true,
			dynamicTyping: true,
			complete: (results) => {
				// console.log("Before...");
				// console.log(results);

				//Massage the data.
				// for(var i = 0; i<results.data.length; i++){
				// 	results.data[i].id = uuid();
				// 	results.data[i].image = "";
				// 	if(results.data[i].attributes !== null){

				// 		if(results.data[i].attributes.includes(",")){
				// 			results.data[i].attributes = results.data[i].attributes.split(',');
				// 		}
				// 		else{
				// 			results.data[i].attributes = [results.data[i].attributes];	
				// 		}

				// 	}
				// 	if(!results.data[i].quantity){
				// 		results.data[i].quantity = "item";
				// 	}
				// }

				for(var i = 0; i<results.data.length; i++){
					results.data[i].id = uuid();
					results.data[i].image = "";
					if(results.data[i].attributes !== null){

						if(results.data[i].attributes.includes(",")){
							results.data[i].attributes = results.data[i].attributes.split(',');
						}
						else{
							results.data[i].attributes = [results.data[i].attributes];	
						}

					}
					if(!results.data[i].quantity){
						results.data[i].quantity = "item";
					}
				}

				// console.log("After...");
				// console.log(results);

				var newInventory = this.props.inventory.concat(results.data);
				this.props.callback(newInventory);
			}
		});
	}

	download = () => {


		// var storageRef = firebaseApp.storage().ref('/csv-templates/');

		// // Create a reference to the file we want to download
		// var starsRef = storageRef.child('bird.png');

		// // Get the download URL
		// starsRef.getDownloadURL().then(function(url) {
		// 	var img = document.getElementById('myimg');
		// 	img.src = url;
		// }).catch(function(error) {

		// // A full list of error codes is available at
		// // https://firebase.google.com/docs/storage/web/handle-errors
		// switch (error.code) {
		// 	case 'storage/object-not-found':
		// 	// File doesn't exist
		// 	break;

		// 	case 'storage/unauthorized':
		// 	// User doesn't have permission to access the object
		// 	break;

		// 	case 'storage/canceled':
		// 	// User canceled the upload
		// 	break;

		// 	case 'storage/unknown':
		// 	// Unknown error occurred, inspect the server response
		// 	break;
		// }
		// });
	}

	componentDidMount(){
		// console.log(this.props);
	}

	printState = () => {
		console.log(this.state);
	}
	render(){
		const csvURL = "https://firebasestorage.googleapis.com/v0/b/" + FIREBASE_CONFIG.STORAGE_BUCKET + "/o/csv-templates%2Fcsv-templates.zip?alt=media&token=459b902a-3f8e-4378-9343-3ec2997ccfa6"
		return(
			<div className="modal-default">
				<h2>Upload via CSV</h2>
				<hr className="title-underline"/>

				<p>The easiest way to upload many items at once to Peruse is with a spreadsheet.</p>

				<p><strong>Please Read:</strong><br/>
				If you <i>have not</i> used this method before it’s important you download and read our very short guide and template to uploading via CSV.
				</p>
				
				<a href={csvURL} target="_blank">Download it here</a>
			
				<p>If you’re ready to go, get started with the button below</p>

				<div className="file-input-wrapper">
  					<button className="btn-file-input">It's cool, I'm ready to upload my file</button>
  					<input type="file" name="files[]" onChange={this.passToParent} />
				</div>
			</div>
		)
	}
}
