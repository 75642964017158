import React, {useState} from 'react';
import validator from 'validator';
import GooglePlacesAutocomplete, { geocodeByPlaceId } from 'react-google-places-autocomplete';

const alphaAndSpacesRegex = '^[a-zA-Z ]*$';

const EditStoreModal = (props) => {

	const [name, setName] = useState(props.shopToEdit.name);
	const [address, setAddress] = useState(props.shopToEdit.address);
	const [description, setDescription] = useState(props.shopToEdit.description);
	const [url, setUrl] = useState(props.shopToEdit.url);
	const [telephone, setTelephone] = useState(props.shopToEdit.telephone);
	const [deliveroo, setDeliveroo] = useState(props.shopToEdit.deliveroo);
	const [deliveroourl, setDeliverooUrl] = useState(props.shopToEdit.deliveroourl);
	const [opentable, setOpenTable] = useState(props.shopToEdit.opentable);
	const [opentableurl, setOpenTableUrl] = useState(props.shopToEdit.opentableurl);
	const [lat, setLat] = useState(props.shopToEdit.lat);
	const [lng, setLng] = useState(props.shopToEdit.lng);

	const passToParent = () => {
		props.callback({
			name,
			address,
			description,
			url,
			telephone,
			deliveroo,
			deliveroourl,
			opentable,
			opentableurl,
			lat,
			lng
		});
	}

	return (
		<div className="new-item-form">
			<h2>Edit Store Details</h2>
			<hr className="title-underline" />

			<p>
				Store ID: <br />
					#{props.shopToEdit.id}
			</p>

			<label htmlFor="name" className="labels">Name</label>
			<div className="input-hint-wrapper">
				<input type="text"
					className="text-input"
					name="name"
					value={name}
					onChange={(e)=>setName(e.target.value)}
					placeholder="Name of your store" />
				<span className="hint">

				</span>
			</div>
			{/* {
				this.state.errorCategory.nameFailed ?
					<span className="input-error">Name must not be empty</span>
					: ''
			} */}

			<label htmlFor="addressLine1" className="labels">Address</label>
			<GooglePlacesAutocomplete
				placeholder="Start typing..."
				onSelect={(location) => {
					geocodeByPlaceId(location.place_id)
						.then(results => {
							setAddress({
								address_components: results[0].address_components,
								formatted_address: results[0].formatted_address
							});
							setLat(results[0].geometry.location.lat());
							setLng(results[0].geometry.location.lng());
						})
						.catch(error => console.error(error));
				}}
			/>
			<p>Current Address <br />
				{address.formatted_address}</p>

			<label className="labels" htmlFor="shop-url">Shop URL</label>
			<div className="input-hint-wrapper">
				<input name="shop-url"
					className="text-input"
					value={url}
					onChange={(e)=>setUrl(e.target.value)}
					type="text"
					placeholder="www.yourshopname.com" />
				<span className="hint">

				</span>
			</div>
			{/* {
				this.state.errorCategory.urlFailed ?
					<span className="input-error">Must be a valid URL</span>
					: ''
			} */}

			<label className="labels" htmlFor="telephone">Telephone</label>
			<div className="input-hint-wrapper">
				<input name="telephone"
					className="text-input"
					value={telephone}
					onChange={(e)=>setTelephone(e.target.value)}
					type="text"
					placeholder="01234567890" />
				<span className="hint">

				</span>
			</div>
			{/* {
				this.state.errorCategory.telephoneFailed ?
					<span className="input-error">Telephone must only contain numbers and no special characters</span>
					: ''
			} */}

			<label className="labels" id="description" htmlFor="description">Description</label>
			<div className="input-hint-wrapper">
				<textarea name="description"
					className="text-input textarea"
					cols="30"
					rows="10"
					value={description}
					onChange={(e)=>setDescription(e.target.value)}
					placeholder="Tell the world about your store!">
				</textarea>
				<span className="hint">

				</span>
			</div>

			{
				deliveroo === "true" ?
					<div>
						<label className="labels" htmlFor="deliveroo">Are you on Deliveroo?</label>
						<input type="radio"
							className="input-radio"
							name="deliveroo"
							value="true"
							onChange={(e)=>setDeliveroo(e.target.value)}
							checked={deliveroo === "true"} /> Yes
						<input type="radio"
							className="input-radio"
							name="deliveroo"
							value="false"
							onChange={(e)=>setDeliveroo(e.target.value)}
							checked={deliveroo === "false"} /> No
					</div>
					: ''
			}

			{
				opentable === "true" ?
					<div>
						<label className="labels" htmlFor="open-table">Are you on OpenTable?</label>
						<input type="radio"
							className="input-radio"
							name="open-table"
							onChange={(e)=>setOpenTable(e.target.value)}
							value="true"
							checked={opentable == "true"} /> Yes
						<input type="radio"
							className="input-radio"
							name="open-table"
							onChange={(e)=>setOpenTable(e.target.value)}
							value="false"
							checked={opentable == "false"} /> No
					</div>
					: ''
			}


			<div className="button-wrapper">
				<button className="button save" onClick={passToParent}>
					Save
				</button>
			</div>
		</div>
	);
}
export default EditStoreModal;



/* 

if (validator.isEmpty(this.state.storeDetails.name)) {
			this.setState((prevState) => ({
				...prevState,
				error: true,
				errorCategory: {
					...prevState.errorCategory,
					nameFailed: true
				}
			}));
		} else {
			this.setState((prevState) => ({
				...prevState,
				error: false,
				errorCategory: {
					...prevState.errorCategory,
					nameFailed: false
				}
			}));
		}

		if (!validator.isEmpty(this.state.storeDetails.url)) {
			//Check if not empty
			if (!validator.isURL(this.state.storeDetails.url)) {
				//Then check if not URL
				this.setState((prevState) => ({
					...prevState,
					error: true,
					errorCategory: {
						...prevState.errorCategory,
						urlFailed: true
					}
				}));
			}
			else {
				this.setState((prevState) => ({
					...prevState,
					error: false,
					errorCategory: {
						...prevState.errorCategory,
						urlFailed: false
					}
				}));
			}
		} else {
			this.setState((prevState) => ({
				...prevState,
				error: false,
				errorCategory: {
					...prevState.errorCategory,
					urlFailed: false
				}
			}));
		}

		if (validator.isEmpty(this.state.storeDetails.telephone) || !validator.isNumeric(this.state.storeDetails.telephone, { no_symbols: true })) {
			this.setState((prevState) => ({
				...prevState,
				error: true,
				errorCategory: {
					...prevState.errorCategory,
					telephoneFailed: true
				}
			}));
		} else {
			this.setState((prevState) => ({
				...prevState,
				error: false,
				errorCategory: {
					...prevState.errorCategory,
					telephoneFailed: false
				}
			}));
		}

		if (this.state.error == false) {
			// console.log(this.state);
			this.props.callback(this.state.storeDetails);
		}
*/