import '@babel/polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import configureStore from './store/configureStore';
import './styles/styles.scss';
import AppRouter from './routers/AppRouter';

const store = configureStore();

const app = (
  <Provider store={store}>
    <AppRouter/>
  </Provider>
); 

ReactDOM.render(app, document.getElementById('app'));
