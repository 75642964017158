import uuid from 'uuid';

// ADD_STORE
//Might need to add the id from firestore
export const addStore = (
	{
		id = '',
		address = {},
		name = '',
		numberOfItems = 0,
		url = '',
		inventory = [],
		telephone = '',
		associatedID = '',
		description = '',
		deliveroourl = '',
		opentableurl = '',
		live = false
	} = {}
) => ({
	type: 'ADD_STORE',
	store: {
		id,
		associatedID,
		address,
		name,
		url,
		numberOfItems,
		telephone,
		inventory,
		description,
		deliveroourl,
		opentableurl,
		live
	}
});

export const updateStore = (id, store) => ({
	type: 'UPDATE_STORE',
	store,
	id
});

export const updateInventory = (id, inventory) => ({
	type: 'UPDATE_INVENTORY',
	id,
	inventory
});

export const resetStore = () => ({
	type: 'RESET_STORES'
});



// REMOVE_EXPENSE
// export const removeExpense = ({ id } = {}) => ({
//   type: 'REMOVE_EXPENSE',
//   id
// });

// // EDIT_EXPENSE
// export const editExpense = (id, updates) => ({
//   type: 'EDIT_EXPENSE',
//   id,
//   updates
// });
