/* External Libraries */
import React from 'react';

// Below lines got deprecated and the export const history = createHistory line
// import createHistory from 'history/createBrowserHistory';
import {createBrowserHistory} from 'history';
// import history from 'history';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
/* Internal Libraries */
import MyStoreMain from '../components/mystoremain';
import NewStore from '../components/store/NewStore';

import InsightsMain from '../components/insightsmain';
import SettingsMain from '../components/settingsmain';
import Store from '../components/store/store';
// import Login from '../components/login';
import AdSite from '../components/adsite/Adsite';
import Login from '../components/login/Login'
import SignUp from '../components/login/SignUp';
import Reset from '../components/login/Reset';
import Header from '../components/header/Header';
import Blog from '../components/BlogMain';

import SixTips from '../components/blog/SixTips';

export const history = createBrowserHistory();

const AppRouter = () => (
  	<Router history={history}>
      	<Switch>
			<Route path="/" component={AdSite} exact={true}/>
			<Route path="/blog" component={Blog} exact/>
			<Route path="/blog/6-tips-to-drive-business-after-coronavirus" component={SixTips}/>
			<Route path="/signup" component={SignUp}/>
			<Route path="/resetpassword" component={Reset}/>
			<Route path="/signin" component={Login}/>
			<div className="min-body">
				<Header/>
				<Route path="/my-stores" component={MyStoreMain}/>
				<Route path="/new-store" component={NewStore}/>
				<Route path="/view-store/:id" component={Store}/>
				<Route path="/insights" component={InsightsMain}/>
				<Route path="/settings" component={SettingsMain}/>
			</div>
    	</Switch>
  	</Router>
);

export default AppRouter;
