import React, {useState} from 'react';
import { Link } from 'react-router-dom';
import validator from 'validator';

import {firebaseApp} from '../../firebase/firebase';

const Reset = () => {

	document.title = "Peruse | Reset Password";

	const [email, setEmail] = useState("");
	const [resetSuccess, setResetSuccess] = useState({
		isSuccessful : false,
		message: "Ok - that's all done, head over to your emails and the rest is up to you 😊"
	});
	const [error, setError] = useState({
		isError : false,
		message : ""
	});

	const onPasswordReset = () => {
		setError({
			isError : false,
			message : ""
		});

		if(!validator.isEmail(email)){
			setError({
				isError : true,
				message : "Not a valid email address"
			});
		}
		else{
			firebaseApp.auth().sendPasswordResetEmail(email).then(()=>{
				setResetSuccess({
					isSuccessful: true,
					message: resetSuccess.message
				});
			}).catch(function(error){
				setError({
					isError : true,
					message : `${error.code}: ${error.message}`
				});
			});
		}
	}

	return(
	<div className="reset-container">
		<div className="reset-form">
			<h2>Reset Your Password</h2>
			<hr className="title-underline" />
			<p className="explanation">
				Don't worry, we all forget things from time to time,
				just enter your email and we'll send you a password
				reset email. Now where are my keys...
			</p>

			<Link to='/signin'>
				Remembered your password? Sign in
			</Link>

			{
				error.isError ? 
				<div>
					<span className="input-error">{error.message}</span>
				</div> 
				: ''	
			}

			{
				resetSuccess.isSuccessful ? 
				<h3 id="confirmation-message">{resetSuccess.message}</h3>
				: 
				""
			}

			<div className="reset-inputs-wrapper">
				<div className="input-wrapper">
					<label htmlFor="email" className="labels">
						Email
					</label>
					<input
						type="text"
						className="text-input"
						name="email"
						value={email}
						onChange={(e)=>setEmail(e.target.value)}
						placeholder="name@email.com"
					/>
				</div>
			</div>
			<div className="centered-button-container">
				<button 
					className="button" 
					onClick={onPasswordReset}>
					Reset
				</button>
			</div>
		</div>
	</div>
	);
}

export default Reset;