import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import {history} from '../../routers/AppRouter';
import { Link } from 'react-router-dom';

import {addUser} from '../../actions/userActions';
import {setLoading} from '../../actions/loadingActions';
import {resetStore, addStore} from '../../actions/storesActions';
import {firebaseApp, firebaseGetStores} from '../../firebase/firebase';

const Login = (props) => {

	document.title = "Peruse | Sign In";

	useEffect(() => {
		//Reset the user.
		props.addUser();

		// function GetIEVersion() {
			// var sAgent = window.navigator.userAgent;
			// var Idx = sAgent.indexOf("MSIE");
							
			// var edge = sAgent.indexOf('Edge/');
  			// if (edge > 0) {
    		// 	// Edge (IE 12+) => return version number
    		// 	return 11;
  			// }
			// // If IE, return version number.
			// if (Idx > 0) 
			// return parseInt(sAgent.substring(Idx+ 5, sAgent.indexOf(".", Idx)));
			
			// // If IE 11 then look for Updated user agent string.
			// else if (!!navigator.userAgent.match(/Trident\/7\./)) 
			// return 11;
			
			// else
			// 	return 0; //It is not IE
			// }

			// if (GetIEVersion() > 0){
			// 	if(window.confirm("Peruse is currently experiencing issues with Internet Explorer and Microsoft Edge, in the meantime please use Google Chrome, Safari, or Firefox ")){
			// 		window.location('https://peruse.app');
			// 	}
			// 	else{
			// 		window.location('https://peruse.app');
			// 	}}
			// else{
			
			// }
	}, []);

	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [error, setError] = useState({
		isError: false,
		message: ""
	});

	const submitSignIn = (e) => {
		
		e.preventDefault();
		props.setLoading(true);

		//Need this otherwise, login-logout causes view of storesx2
		props.resetStore();
	
        firebaseApp.auth().signInWithEmailAndPassword(email, password).then((UserCredential)=>{
			// Grab UID, push to reducer.
			firebaseApp.firestore().collection('users').where("associatedID", "==", UserCredential.user.uid).get().then((snapshot)=>{
				snapshot.docs.forEach(doc => {
					//Get data, add to reducer.
					const user = doc.data();
					props.addUser(user);
				});
			});
        }).then(()=>{
			props.setLoading(false);
			// console.log("About to push to my-stores");
			props.history.push("/my-stores");
			// console.log(props);
		}).catch((error) => {
			setError({
				isError: true,
				message: `${error.code}: ${error.message}`
			});
        });
	}

	return(
		<div className="sign-in-container">
			<div className="sign-in-form ">
				<h2>Business Sign In</h2>
				<hr className="title-underline" />
		
				<p>
					Shopper? All you need is the app!
				</p>

				{
					error.isError ? 
					<div>
						<span className="input-error">{error.message}</span>
					</div> :
					''	
				}
				<form onSubmit={submitSignIn}>
					<div className="sign-in-inputs-wrapper vert-form">
						<div className="input-wrapper">
							<label 
								htmlFor="email" 
								className="labels">
									Email
							</label>
							<input
								name="email"
								type="text"
								className="text-input"
								value={email}
								onChange={e=>setEmail(e.target.value)}
							/>
						</div>
						
						<div className="input-wrapper">
							<label 
								className="labels" 
								htmlFor="password">
								Password
							</label>
							<input
								name="password"
								type="password"
								className="text-input"
								value={password}
								onChange={e=>setPassword(e.target.value)}
							/>
						</div>
					</div>

					<Link to='/signup'>
						Don't have an account? Sign Up
					</Link><br/>
					<Link to='/resetpassword'>
						Forgot your password? Reset it
					</Link>

					<div className="centered-button-container">
						<button 
							type="submit"
							className="button" 
							>
								Sign In
						</button>
					</div>
				</form>
			</div>
		</div>
	);
}

/*
  This is used with connect()() to basically say
  Give the application MyStoreMain the properties mapDispatchToProps
  giving MyStoreMain the ability to say this.props.dispatch
  and then send something to the reducer

  Same for mapStateToProps, it's just saying let the component use
  this.props.stores which is data from the ReduxStore
*/
const mapDispatchToProps = (dispatch) => ({
	addUser: (store) => dispatch(addUser(store)),
	addStore: (store) => dispatch(addStore(store)),
	resetStore: () => dispatch(resetStore()),
	setLoading : (state) => dispatch(setLoading(state))
});
  
const mapStateToProps = (state) => {
	return {
		user : state.userReducer
	}
};
  
export default connect(mapStateToProps, mapDispatchToProps)(Login);