import React from 'react';
import {FIREBASE_CONFIG} from '../../../config/config';
import moment from 'moment';

const InventoryTableRow = (props) => {	

	const openEdit = () => {
		var itemToEdit = {
			...props.data,
			key: props.position
		};
		props.callback(itemToEdit);
	}

	return(
	<tr className="inventory-row" onClick={openEdit} key={props.position}>
		<td>{props.name}</td>
		<td>
			{
				props.deal.active ? 
				<img src="/images/check.svg"/> : <img src="/images/cross.svg"/> 
			}
		</td>
		{/* <td className="attributes">{props.attributes ? props.attributes.join(", ") : ''}</td> */}
		<td>&pound;{props.price}</td>
		<td>
			{
				props.deal.price !== 'N/A' && props.deal.price !== "" ? 
				<span>&pound;{props.deal.price}</span>		
				: 
				<span className="no-data">N/A</span> 
			}
		</td>
		<td>{props.quantity}</td>
		<td>
			{
				props.deal.quantity !== 'N/A' && props.deal.quantity !== "" ? 
				props.deal.quantity 
				: 
				<span className="no-data">N/A</span> 
			}
		</td>
		<td>
			{
				(props.deal.from !== 'N/A' && props.deal.to !== 'N/A' && props.deal.from !== "" && props.deal.to !== "") ? 
				`${moment(props.deal.from, "x").format("DD/MM")} - ${moment(props.deal.to, "x").format("DD/MM")}`
				: 
				<span className="no-data">N/A</span> 
			}
			{/*  || (props.deal.from !== "" || props.deal.to !== "") */}			
		</td>
		<td>
			{
				props.image && props.image !== 'Loading Image' ? 
				<div className="table-row-image-wrapper">
					<img src={"https://firebasestorage.googleapis.com/v0/b/" + FIREBASE_CONFIG.STORAGE_BUCKET + "/o/inventory-images%2F" + props.image + "?alt=media"} height="30px" alt=""/>
				</div>
				: <span className="no-data">No Image</span>
			}
		</td>
	</tr>	
	)
}
//This stops the component throwing an error if the deal doesn't exist 
InventoryTableRow.defaultProps = {
	deal : {
		active: false,
		price: 'N/A',
		quantity: 'N/A',
		from: 'N/A',
		to: 'N/A'
	}
}

export default InventoryTableRow;