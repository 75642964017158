import React from 'react';
import { Link } from 'react-router-dom';

const Logo = () => {
	return(
		<Link to="/" className="logo-and-title">
			<img src="/images/logo.svg" alt="peruse logo"/>
			<h1>Peruse</h1>
		</Link>
	);
} 

export default Logo;