import React from 'react';
import {Link} from 'react-router-dom';
// import Logo from '../../../public/images/peruse-logo.png';
// /images/peruse-logo.png
const AdSiteHeader = () => {
	return(
		<header>
			<div className="header-content">
				<Link to="/">
					{/* <img src={Logo}
						alt="peruse logo"/> */}

						<img srcset="/images/peruse-logo.png 1x, /images/peruse-logo@2x.png 2x"
						src="/images/peruse-logo.png" 
						alt="peruse logo"/>
				</Link>
				<nav>
					<Link to="/blog">
						Blog
					</Link>
					<Link to="/signin">
						Sign In/Sign Up
					</Link>
				</nav>
			</div>
		</header>
	);
};
export default AdSiteHeader;