/* 
    Simple file to setup the navigation

    Then in the <nav> there is navlinks, activeClassName is a class they get when
    they are selected. In the <img> is a ternary operator to decide 
    "If we're on the right URL, use the active icon, otherwise use the plain one"
    -> Same for the arrow up, be transparent unless we're on the right URL
*/

import React, {useState} from 'react';
import { NavLink } from 'react-router-dom';

const Navigation = () => {
	const [navigationState, setNavigationState] = useState({
		mystore: true,
		mysubscription: false,
		insights: false,
		settings: false
	});

	const myStoreActive = {
		mystore: true,
		mysubscription: false,
		insights: false,
		settings: false
	}
	const myInsightsActive = {
		mystore: false,
		mysubscription: false,
		insights: true,
		settings: false
	}
	const mySettingsActive = {
		mystore: false,
		mysubscription: false,
		insights: false,
		settings: true
	}
	return (
		<nav className={"nav-items"}>
			<ul>
				<NavLink to="/my-stores" className={navigationState.mystore ? 'is-active' : null} exact={true} onClick={() => setNavigationState(myStoreActive)}>
					<div className="icon-text-wrapper">
						<img src={navigationState.mystore ? "/images/icons/my-store-icon-active.svg" : "/images/icons/my-store-icon.svg"}
							alt="" />
						<span>My Stores</span>
					</div>
					<div className={navigationState.mystore ? "arrow-up" : "arrow-up clear"}></div>
				</NavLink>

				<NavLink to="/insights" className={navigationState.insights ? 'is-active' : null} onClick={() => setNavigationState(myInsightsActive)}>
					<div className="icon-text-wrapper">
						<img src={navigationState.insights ? "/images/icons/insight-icon-active.svg" : "/images/icons/insight-icon.svg"}
							alt="" />
						<span>Insights</span>
					</div>
					<div className={navigationState.insights ? "arrow-up" : "arrow-up clear"}></div>
				</NavLink>
				<NavLink to="/settings" className={navigationState.settings ? 'is-active' : null} onClick={() => setNavigationState(mySettingsActive)}>
					<div className="icon-text-wrapper">
						<img src={navigationState.settings ? "/images/icons/settings-icon-active.svg" : "/images/icons/settings-icon.svg"}
							alt="" />
						<span>Settings</span>
					</div>
					<div className={navigationState.settings ? "arrow-up" : "arrow-up clear"}></div>
				</NavLink>
			</ul>
		</nav>
	)
}

/* 

let isStoreClass = () => {
			
		Function needed just for the /my-store because we want to highlight this if my-store or view-store
				and can't put logical OR operator inside ternary operator
		
			if (window.location.pathname.includes("my-stores") || window.location.pathname.includes("view-store")) {
				return "is-active";
			}
			else {
				return "";
			}
		}
		let isStoreURL = () => {
			
				Function needed just for the /my-store because we want to highlight this if my-store or view-store
				and can't put logical OR operator inside ternary operator
			
			if (window.location.pathname.includes("my-stores") || window.location.pathname.includes("view-store")) {
				return true;
			}
			else {
				return false;
			}
		}
		var url = window.location.pathname;



	{/* <NavLink to="/my-subscription" className={navigationState.mysubscription ? 'is-active' : null} onClick={setNavigationState(mySubscriptionActive)}>
		<div className="icon-text-wrapper">
			<img src={url === '/my-subscription' ? "/images/icons/my-subscription-icon-active.svg" : "/images/icons/my-subscription-icon.svg"}
								alt="" />
			<span>My Subscription</span>
		</div>
		<div className={url === '/my-subscription' ? "arrow-up" : "arrow-up clear"}></div>
	</NavLink> 
		const mySubscriptionActive = {
		mystore: false,
		mysubscription: true,
		insights: false,
		settings: false
	}
	
*/

export default Navigation;