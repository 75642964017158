import React from 'react';
// import { Link } from 'react-router-dom';

const Footer = () => {
	return (
		<footer>
			<div className="footer-content">
				<img
					srcset="/images/peruse-logo-inverted.png 1x, /images/peruse-logo-inverted@2x.png 2x" 
					src="/images/peruse-logo-inverted.png" 
					width="184px" 
					alt="peruse logo inverted"/>
				<div class="menu-lists">
					<ul>
						<li>Contact</li>
						<li>support@peruse.app</li>
					</ul>
					<ul>
						<li>Official Stuff</li>
						<li>
							<a href="https://www.dropbox.com/s/xiuii1inhc201t1/Peruse%20Privacy%20Policy.pdf?dl=1">
								Privacy Policy
							</a>
						</li>
						<li>
							<a href="#">
								Terms &amp; Conditions
							</a>
						</li>
					</ul>
					<ul>
						<li>Follow Us</li>
						<li>
							<a href="https://www.twitter.com/perusehq" target="_blank">
								<img src="/images/twitter.svg" alt="twitter logo"/>
							</a>
						</li>
					</ul>
				</div>
				<div className="company-info">
					<p>Company No. 11396831</p>
					<p>&copy; Peruse App Ltd 2020, All Rights Reserved</p>
				</div>
			</div>
		</footer>		
	);
}
  
export default Footer;