import React, { useState } from 'react';
import { v4 as uuid } from 'uuid';
import moment from 'moment';
import validator from 'validator';

import { firebaseApp } from '../../../firebase/firebase';
import { FIREBASE_CONFIG } from '../../../../config/config';

const AddItemForm = (props) => {

	const [name, setName] = useState("");
	const [attributes, setAttributes] = useState("");
	const [price, setPrice] = useState("");
	// const [availableOnRequest, setAvailableOnRequest] = useState(false);
	const [quantity, setQuantity] = useState("item");
	const [brand, setBrand] = useState("");
	const [description, setDescription] = useState("");
	const [image, setImage] = useState("");
	const [progress, setProgress] = useState(0);

	const [dealprice, setDealPrice] = useState("");
	const [dealquantity, setDealQuantity] = useState("item");
	const [dealdatefrom, setDealDateFrom] = useState("");
	const [dealdateto, setDealDateTo] = useState("");
	const [dealactive, setDealActive] = useState(false);

	const [error, setError] = useState({
		isError: false,
		message: ""
	});

	const uploadImage = (e) => {
		let file = e.target.files[0];

		const uniqueID = uuid();

		var storageRef = firebaseApp.storage().ref('inventory-images/' + uniqueID);
		var task = storageRef.put(file);

		task.on('state_changed', (snapshot) => {
			var percentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
			setProgress(percentage);
		},(err) => {
			//If error.
			console.log(err);
		},() => {
			setImage(uniqueID);
		});
	}

	const sendToParent = (e) => {

		//Reset errors
		setError({
			isError: false,
			message: ""
		});

		if(dealactive && (dealprice == "" || dealdatefrom == "" || dealdateto == "")){
			setError({
				isError: true,
				message: "You can't have an active deal with incomplete deal information"
			});
			//Scroll to top of modal.
			document.getElementsByClassName('ReactModal__Content')[0].scrollTo(0, 0);
		}
		else if(price == "" || name == ""){
			setError({
				isError: true,
				message: "Items must have a Name and Price"
			});
			//Scroll to top of modal.
			document.getElementsByClassName('ReactModal__Content')[0].scrollTo(0, 0);
		}
		else{
			//Massage data
			//Make it into an array via the commas
			var attributesArray = attributes.split(',');
			//Chop off the edges
			for (var j = 0; j < attributesArray.length; j++) {
				attributesArray[j] = attributesArray[j].trim();
			}
			var newItem = {
				id: uuid(),
				name,
				attributes: attributesArray,
				price,
				quantity,
				brand,
				description,
				image,
				deal : {
					price : dealprice,
					quantity : dealquantity,
					from: validator.isDate(dealdatefrom, "YYYY-MM-DD") ? moment(dealdatefrom, "YYYY-MM-DD").valueOf().toString() : dealdatefrom,
					to: validator.isDate(dealdateto, "YYYY-MM-DD") ? moment(dealdateto, "YYYY-MM-DD").valueOf().toString() : dealdateto,
					active: dealactive
				}
			};		
			props.callback(newItem, e.target.value);	
			// Set the inputs back to nothing if 'add-another'
			if(e.target.value === 'add-another'){
				setName("");
				setAttributes("");
				setPrice("");
				setQuantity("item");
				setBrand("");
				setDescription("");
				setImage("");
				setProgress(0);
				setDealPrice("");
				setDealQuantity("item");
				setDealDateFrom("");
				setDealDateTo("");
				setDealActive(false);
			}
		}
	}

	const resetDeal = () => {
		setDealPrice("");
		setDealQuantity("item");
		setDealDateFrom("");
		setDealDateTo("");
		setDealActive(false);
	}

	return (
		<div className="new-item-form">
			<h2>Add Item</h2>
			<hr className="title-underline" />
			
			<p className="lag-notification">
				Items take around 2 hours to appear in the Peruse app
			</p>
			{
				error.isError ? 
					<span className="errorMessage">{error.message}</span> 
					: 
					''
			}

			<label htmlFor="name" className="labels">Name*</label>
			<div className="input-hint-wrapper">
				<input type="text"
					className="text-input"
					name="name"
					value={name}
					onChange={(e) => setName(e.target.value)}
					placeholder="Peanut Butter Cups" />
				<span className="hint">
					Be factual here, don’t describe it, just say what it is. If its a broom, just write “Broom”
				</span>
			</div>

			<label className="labels" htmlFor="attributes">Attributes</label>
			<div className="input-hint-wrapper">
				<input name="attributes"
					className="text-input"
					value={attributes}
					onChange={(e) => setAttributes(e.target.value)}
					type="text"
					placeholder="vegan, 6-pack, blue" />
				<span className="hint">
					Seperate all values with a comma. Avoid adjectives like ‘delicious’ and ‘amazing’
				</span>
			</div>

			<label className="labels" htmlFor="price">Price*</label>
			<div className="input-hint-wrapper">
				<input name="price"
					className="text-input"
					value={price}
					onChange={(e) => setPrice(e.target.value)}
					type="text"
					placeholder="9.99"
					/>
				<span className="hint">
					No need to include currency here
				</span>
			</div>

			<label className="labels" htmlFor="quantity">Quantity</label>
			<div className="input-hint-wrapper">
				<select name="quantity"
					value={quantity}
					onChange={(e) => setQuantity(e.target.value)}
					id="quantity">
					<option value=""></option>
					<option value="item">Per Item</option>
					<option value="pack">Per Pack</option>
					<option value="gram">Per Gram</option>
					<option value="kilogram">Per Kilogram</option>
					<option value="hour">Per Hour</option>
					<option value="service">Per Service</option>
				</select>
				<span className="hint">
					This is how many you get for the price listed above
				</span>
			</div>

			<label className="labels" htmlFor="brand">Brand</label>
			<div className="input-hint-wrapper">
				<input name="brand"
					className="text-input"
					value={brand}
					onChange={(e) => setBrand(e.target.value)}
					type="text"
					placeholder="Reese's" />
				<span className="hint">
					Nike? Cadbury? Leave it blank if it doesn't have a brand
				</span>
			</div>

			<label className="labels" htmlFor="description">Description</label>
			<div className="input-hint-wrapper">
				<textarea name="description"
					id=""
					className="text-input"
					cols="30"
					rows="10"
					value={description}
					onChange={(e) => setDescription(e.target.value)}
					placeholder="A little something more about the product">
				</textarea>
				<span className="hint">
					Full detail of the product - feel free to use descriptions from elsewhere about the product.
				</span>
			</div>

			<label className="labels" htmlFor="image">Image</label>
			{
				image ?
					<img src={"https://firebasestorage.googleapis.com/v0/b/" + FIREBASE_CONFIG.STORAGE_BUCKET + "/o/inventory-images%2F" + image + "?alt=media"} height="100px" alt="" />
					: ''
			}
			<div className="input-hint-wrapper">
				<input name="image"
					type="file"
					onChange={uploadImage} />
				<span className="hint">
					Good images can boost views more than 80% compared to those that don't
				</span>
			</div>
			<progress value={progress} max="100" id="progress"></progress>


			<div className="deal">
				<div className="title-reset-wrapper">
					<h3>Deal</h3>

					<button 
						className="reset-deal"
						onClick={resetDeal}>
							Clear
					</button>
				</div>

				<label className="labels" htmlFor="dealprice">Deal Price</label>
				<div className="input-hint-wrapper">
					<input name="dealprice"
						className="text-input"
						value={dealprice}
						onChange={(e) => setDealPrice(e.target.value)}
						type="text"
						placeholder="" />
					<span className="hint">
						This is the price of the product during the deal period
					</span>
				</div>

				<label className="labels" htmlFor="dealquantity">Deal Quantity</label>
				<div className="input-hint-wrapper">
					<select name="dealquantity"
						value={dealquantity}
						onChange={(e) => setDealQuantity(e.target.value)}
						id="dealquantity">
						<option value="item">Per Item</option>
						<option value="pack">Per Pack</option>
						<option value="gram">Per Gram</option>
						<option value="kilogram">Per Kilogram</option>
						<option value="hour">Per Hour</option>
						<option value="service">Per Service</option>
					</select>
					<span className="hint">
						This is how many you get for the price while the deal is on
					</span>
				</div>

				<label className="labels" htmlFor="dealfrom">From</label>
				<div className="input-hint-wrapper">
					<input name="dealfrom"
						className="date-input"
						type="date"
						value={dealdatefrom}
						onChange={(e)=>setDealDateFrom(e.target.value)}/>
					<span className="hint">
						When the deal starts
					</span>
				</div>

				<label className="labels" htmlFor="dealend">To</label>
				<div className="input-hint-wrapper">
					<input 
						name="dealend"
						className="date-input"
						type="date"
						value={dealdateto}
						onChange={(e)=>{setDealDateTo(e.target.value); console.log(e.target.value)}}/>
					<span className="hint">
						When the deal ends
					</span>
				</div>

				<div className="input-hint-wrapper radio-hint-wrapper">
					<div className="radio-wrapper">
						<input 
							name="active-radio"
							type="radio"
							id="active-radio"
							value={true}
							checked={dealactive==true}
							onChange={()=>setDealActive(true)}
							/>
						<label>Active</label>
						<input 
							name="active-radio"
							type="radio"
							value={false}
							id="not-active-radio"
							checked={dealactive==false}
							onChange={()=>setDealActive(false)}
							/>
						<label>Not Active</label>
					</div>
					<span className="hint">
						Choose to disable your offer (even if it's in date)
					</span>
				</div>

				<p className="terms-label">
					By uploading this deal you agree that you understand it is illegal to advertise offers which do not comply with UK legislation around Business Protection from Misleading Marketing Regulations
				</p>
				<a className="terms-url"
					target="_blank"
					href="http://www.legislation.gov.uk/uksi/2008/1276/pdfs/uksi_20081276_en.pdf">
						http://www.legislation.gov.uk/uksi/2008/1276/pdfs/uksi_20081276_en.pdf
				</a>
			</div>

			<div className="button-wrapper">
				<button className="button save-add-another"
					value="add-another"
					onClick={sendToParent}>
					Save &amp; Add Another
				</button>
				<button className="button finish"
					value="finish"
					onClick={sendToParent}>
					Finish
				</button>
			</div>
		</div>
	)
}

export default AddItemForm;