/* 
    Concatenation of the Logo, Sign out and Navigation
*/

import React from 'react';
import { connect } from 'react-redux';
import Logo from './Logo';
import SignOutButton from './SignOutButton';
import Navigation from './Navigation';


const Header = (props) => {
	return (
		<div className="navigation">
			<div className="logo-signout-wrapper">
				<Logo />
				{props.loading ? <span className="app-loading">Loading...</span> : null}
				<SignOutButton />
			</div>
			<Navigation/>
		</div>
	);
}

const mapStateToProps = (state) => {
	return {
		loading: state.loadingReducer
	}
};

export default connect(mapStateToProps, undefined)(Header);
//hello world
// export default connect(mapStateToProps, undefined)(Header);