import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import Modal from 'react-modal';

import {firebaseApp, updateCollection} from '../../firebase/firebase';
import {history} from '../../routers/AppRouter';
import InventoryTableRow from './InventoryTableRow';
import EditStoreModal from './modals/EditStoreModal';
import CSVUploadModal from './modals/CSVUploadModal';
import EditItemForm from './modals/EditItemForm';
import AddItemForm from './modals/AddItemForm';
import DeleteStoreForm from './modals/DeleteStoreForm';
import {addStore, updateStore, resetStore} from '../../actions/storesActions';
import LiveSwitch from './controls/LiveSwitch';

const customStyles = {
	content : {
		maxHeight			  : '90vh',
		overflowY 			  : 'scroll',
		top                   : '50%',
		left                  : '50%',
		right                 : 'auto',
		bottom                : 'auto',
		marginRight           : '-50%',
		transform             : 'translate(-50%, -50%)'
	}
};

Modal.setAppElement('#app');

const Store = (props) => {

	document.title = "Peruse | My Stores";

	const [modalIsOpen, setModalIsOpen] = useState(false);
	const [modalContent, setModalContent] = useState({
		editStore : false,
		addItem : false,
		editItem : false,
		uploadViaCSV : false,
		deleteStore : false	
	});
	const [itemToEdit, setItemToEdit] = useState({});
	const [storeID, setStoreID] = useState("");
	const [storeDetail, setStoreDetail] = useState({
		name: "Loading Name",
		address : {
			line1 : "Loading Address"
		},
		inventory: [{
			attributes : ["Loading Data"],
			name: "Loading Data",
			price: 0.00,
			key : '',
			position : '',
			data : '',
			brand : 'Loading Brand',
			quantity : 'Loading Quantity',
			image : 'Loading Image',
			deal : {
				active : 'Loading',
				quantity: "Loading",
				price : "Loading",
				from : 'Loading',
				to : 'Loading'
			}
		}]
	});
	const [filter, setFilter] = useState({
		firstIndex : 0,
		spacing : 25
	});
	const [error, setError] = useState({
		isError : false,
		message : "there has been an error"
	});

	const handleDataFromAddItem = (data, command) => {
		//Create new variable thats a replica of inventory
		var newInventory = storeDetail.inventory;

		//Put the new item at the start of the array
		newInventory.unshift(data);	


		//Set the new state of inventory and reset newItem 
		setStoreDetail({
			...storeDetail,
			inventory: newInventory,
		});
		setModalIsOpen(false);

		//If add-another re-open the modal blank  
		if(command === 'add-another'){
			setModalIsOpen(true);
		}
		//If finish, close modal and update firebase
		else if(command === 'finish'){
			firebaseApp.firestore().collection('stores').doc(props.match.params.id).update({
				inventory: storeDetail.inventory
			}).then(()=>{
				//Tell reducer
				props.updateStore(storeID, storeDetail);
			}).catch((error)=>{
				setError({
					isError: true,
					message: `${error.code}: ${error.message}`
				});
			});	
		}
	}
	
	const handleDataFromEditItem = (data, command) => {
		var newInventory = storeDetail.inventory;
		// console.log("Data handled in edit item");
		// console.log(data);
		if(command === 'save'){
			newInventory[data.key] = {
				name: data.name,
				attributes: data.attributes || '', 
				price : data.price,
				brand : data.brand || '',
				quantity : data.quantity,
				description : data.description || '',
				image : data.image || '',
				deal : {
					...data.deal
				}
			}
		}
		else if(command === 'delete'){
			newInventory.splice(data.key, 1);
		}
		//Set the new state of inventory and reset newItem
		setStoreDetail({
			...storeDetail,
			inventory: newInventory
		});
		setModalIsOpen(false);
			//Make sure this happens after the last command...
			//Push to Firestore
			firebaseApp.firestore().collection('stores').doc(props.match.params.id).update({
			inventory: storeDetail.inventory
		}).then(()=>{
			//Tell reducer
			props.updateStore(storeID, storeDetail);
		}).catch((error)=>{
			setError({
				isError: true,
				message: `${error.code}: ${error.message}`
			});
		});
	}
	const handleDataFromEditStore = (data) => {
		setStoreDetail({
			...storeDetail,
			...data
		});
		setModalIsOpen(false);

		updateCollection('stores', props.match.params.id, {
			address : data.address,
			deliveroourl: data.deliveroourl,
			description : data.description,
			name : data.name,
			opentableurl : data.opentableurl,
			telephone : data.telephone,
			url : data.url
		}).then(() => {
			//Update store
			props.updateStore(storeID, storeDetail);
			// console.log("Updated store info!");
		}).catch((error)=>{
			setError({
				isError: true,
				message: `${error.code}: ${error.message}`
			});
		});
	}

	const handleDataFromDeleteStore = (command) => {
		if(command === 'delete'){
			firebaseApp.firestore().collection('stores').doc(props.match.params.id).delete().then(()=>{
				console.log("Store deleted");
				//Reset Redux
				props.resetStore();

				//Call data again
				firebaseApp.firestore().collection('stores').where("associatedID", "==", props.user.associatedID).get().then((snapshot)=>{
					snapshot.docs.forEach(doc => {
						let store = doc.data();
						//You have to pull the document id from doc, not doc.data()
						store.id = doc.id;
						//Tell Redux
						props.addStore(store);
					});
				}).then(()=>{
					history.push('/my-stores');
				}).catch((error)=>{
					setError({
						isError: true,
						message: `${error.code}: ${error.message}`
					});
				});
			});
		}
		else if(command === 'cancel'){
			setModalIsOpen(false);
		}
	}
	const openModal = () => {
		setModalIsOpen(true);
	}
	const openAddItemModal = () => {
		setModalContent({
			editStore : false,
			addItem : true,
			editItem : false,
			uploadViaCSV : false,
			deleteStore : false
		});
		setModalIsOpen(true);
	}
	const openEditItemModal = (itemToEdit) => {
		setModalContent({
			editStore : false,
			addItem : false,
			editItem : true,
			uploadViaCSV : false,
			deleteStore : false
		});
		setItemToEdit(itemToEdit);
		setModalIsOpen(true);
	}
	const openCSVUploadModal = () => {
		setModalContent({
			editStore : false,
			addItem : false,
			editItem : false,
			uploadViaCSV : true,
			deleteStore : false
		});
		setModalIsOpen(true);
	}
	const closeModal = () => {
		setModalIsOpen(false);
	}
	const editStoreInfo = () => {
		setModalContent({
			editStore : true,
			addItem : false,
			editItem : false,
			uploadViaCSV : false,
			deleteStore : false
		});
		setModalIsOpen(true);
	}

	const deleteStore = () => {
		setModalContent({
			editStore : false,
			addItem : false,
			editItem : false,
			uploadViaCSV : false,
			deleteStore : true
		});
		setModalIsOpen(true);
	}

	useEffect(() => {
		//Grab store == this ID in URL, && associatedID to store == ID in URL
		for(var x = 0; x<props.stores.length; x++){
			if(props.stores[x].id == props.match.params.id){
				setStoreID(x);
				setStoreDetail(props.stores[x]);
			}
		}
	}, []);

	const handleLiveSwitch = (liveState) => {
		firebaseApp.firestore().collection('stores').doc(props.match.params.id).update({
			live: liveState
		}).then(()=>{
			// Confirm with success message
			//Tell redux
			console.log("Pushed to firebase!");
			props.updateStore(storeID, {...storeDetail, live: liveState});
		}).catch((error)=>{
			setError({
				isError: true,
				message: `${error.code}: ${error.message}`
			});
		});
	}

	const closeError = () => {
		setError({
			isError: false,
			message: ""
		});
	}  
	const afterOpenModal = () => {

	}
	const handleCSVUpload = (newInventory) => {
		setStoreDetail({
			...storeDetail,
			inventory: newInventory
		});
		setModalIsOpen(false);

		firebaseApp.firestore().collection('stores').doc(props.match.params.id).update({
			inventory: storeDetail.inventory
		}).then(()=>{
			// Confirm with success message
			console.log("Pushed to firebase!");
		}).catch((error)=>{
			setError({
				isError: true,
				message: `${error.code}: ${error.message}`
			});
		});
	}
	const onSpacingChange = (e) => {
		const spacing = Number(e.target.value);
		setFilter({
			firstIndex: 0,
			spacing: spacing
		});
	}
	const next = () => {
		setFilter({
			...filter,
			firstIndex : filter.firstIndex + filter.spacing,
		});
	}
	const previous = () => {
		setFilter({
			...filter,
			firstIndex : filter.firstIndex - filter.spacing,
		});
	}

	const inventoryTable = storeDetail.inventory.slice(filter.firstIndex, (filter.firstIndex + filter.spacing)).map((item, i) => (
		<InventoryTableRow
			callback={openEditItemModal}
			key={i}
			position={i}
			data={item}
			deal={item.deal}
			name={item.name}
			attributes={item.attributes}
			price={item.price}
			brand={item.brand}
			quantity={item.quantity}
			image={item.image}/>
	));

	return (
		<div className="view-store">
		  {
			  error.isError ? 
			  <div className="error-wrapper">
				  <span className="error-message">{error.message}</span>

				  <button className="error-close" onClick={closeError}>
					  <img src="/images/icons/cross.svg" alt=""/>
				  </button>
			  </div>  : ''					
		  }

		<Modal
			isOpen={modalIsOpen}
			onAfterOpen={afterOpenModal}
			onRequestClose={closeModal}
			contentLabel="Example Modal"
			style={customStyles}>

				{modalContent.editItem ? 
					<EditItemForm 
						callback={handleDataFromEditItem} 
						itemToEdit={itemToEdit} /> : '' }

				{modalContent.addItem ?
					<AddItemForm 
						callback={handleDataFromAddItem}/> : ''} 
				  
				{modalContent.editStore ? 
					<EditStoreModal 
						callback={handleDataFromEditStore} 
						shopToEdit={storeDetail}/> : ''}

				{modalContent.uploadViaCSV ? 
					<CSVUploadModal 
						callback={handleCSVUpload}
						inventory={storeDetail.inventory}/> : ''}

				{modalContent.deleteStore ? 
					<DeleteStoreForm 
						callback={handleDataFromDeleteStore}/> : ''}	
	  
		</Modal>

		<div className="controls-info-wrapper">
			<div className="store-info">
				<span>{storeDetail.name},<br/> {storeDetail.address.formatted_address}</span>
				<button className="editButton" onClick={editStoreInfo}>
					<img src="/images/icons/edit-icon.svg" alt="edit store"/>
					  Edit
				</button>
				<button className="deleteButton" onClick={deleteStore}>
					<img src="/images/icons/trash-icon.svg" alt="delete store"/>
					Delete
				</button>
			</div>

			<div className="controls">
				<LiveSwitch callback={handleLiveSwitch} isLive={storeDetail.live}/>
				<button onClick={openAddItemModal}>
					<img className="add-item-icon" src="/images/icons/add-item-icon.svg" alt="add item"/>
						Add Item
				</button>
				{/*<button onClick={this.exportTest}>
					<img src="/images/icons/export-icon.svg" alt=""/>
					  Export
				</button>*/}
				{/* <button onClick={openCSVUploadModal}>
					<img classname="upload-csv-icon" src="/images/icons/upload-icon.svg" alt="upload via csv"/>
						Upload via CSV
				</button> */}
			</div>
		</div>

		<div className="table-title">
			<h2>Products, Services &amp; Deals</h2>
		</div>

		<table className="inventory-table">
			<thead>
				<tr>
					<th>Name</th>
					<th>Deal Active</th>
					<th>Price</th>
					<th>Deal Price</th>
					<th>Quantity</th>
					<th>Deal Quantity</th>
					<th>Deal Dates</th>
					<th>Image</th>
				</tr>
			</thead>
			<tbody>
				{inventoryTable}
			</tbody>
		</table>
		<div className="inventory-table-footer">
			<div className="showing-wrapper">
				<label htmlFor="showing">Showing</label>
				<select 
					name="showing" 
					value={filter.spacing}
					onChange={onSpacingChange}
					id="">
					<option value="25">25</option>
					<option value="50">50</option>
					<option value="100">100</option>
				</select>
			</div>
			  
			<div className="next-prev-wrapper">
				<button 
					className="previous" 
					onClick={previous}>
						Previous
				</button>
				<button 
					className="next"
					onClick={next}>
						Next
				</button>
			</div>
			<div>
				<span>Showing {filter.firstIndex}-{filter.firstIndex + filter.spacing}</span>
			</div>
		</div>	
	</div>
  	);
}

const mapDispatchToProps = (dispatch) => ({
	addStore: (store) => dispatch(addStore(store)),
	updateStore : (id, store) => dispatch(updateStore(id, store)),
	resetStore: () => dispatch(resetStore())
});

const mapStateToProps = (state) => {
	return {
		user : state.userReducer,
		stores : state.storesReducer
	}
};


export default connect(mapStateToProps, mapDispatchToProps)(Store);

// export default Store2;


//export class Store extends React.Component {
	// 	state = {
	// 		modalIsOpen: false,
	// 		editItem : false,
	// 		addItem : false,
	// 		editStore : false,
	// 		uploadViaCSV : false,
	// 		deleteStore : true,
	// 		itemToEdit: {},
	// 		storeID : '',
	// 		storeDetail : {
	// 			name: "Loading Name",
	// 			address : {
	// 				line1 : "Loading Address"
	// 			},
	// 			inventory: [{
	// 				attributes : ["Loading Data"],
	// 				name: "Loading Data",
	// 				price: 0.00,
	// 				key : '',
	// 				position : '',
	// 				data : '',
	// 				brand : 'Loading Brand',
	// 				quantity : 'Loading Quantity',
	// 				image : 'Loading Image'
	// 			}]
	// 		},
	// 		filter : {
	// 			firstIndex : 0,
	// 			spacing : 25
	// 		},
	// 		error : false,
	// 		errorMessage: 'there has been an error'
	// 	}
	
	// 	handleDataFromAddItem = (data, command) => {
	// 		//Create new variable thats a replica of inventory
	// 		var newInventory = this.state.storeDetail.inventory;
	
	// 		//Put the new item at the start of the array
	// 		newInventory.unshift(data);	
	
	// 		//Set the new state of inventory and reset newItem 
	// 		this.setState((prevState)=>({
	// 			storeDetail : {
	// 				...prevState.storeDetail,
	// 				inventory: newInventory
	// 			},
	// 			modalIsOpen: false	
	// 		}), () => {
	
	// 			//If add-another re-open the modal blank  
	// 			if(command === 'add-another'){
	// 				this.setState(({
	// 					modalIsOpen: true
	// 				}));
	// 			}
	// 			//If finish, close modal and update firebase
	// 			else if(command === 'finish'){
	// 				firebaseApp.firestore().collection('stores').doc(this.props.match.params.id).update({
	// 					inventory: this.state.storeDetail.inventory
	// 				}).then(()=>{
	// 					//Tell reducer
	// 					this.props.updateStore(this.state.storeID, this.state.storeDetail);
	// 				}).catch((error)=>{
	// 					this.setState(()=>({
	// 						error : true,
	// 						errorMessage : error
	// 					}))
	// 				});	
	// 			}
	// 		});
	// 	}
	// 	handleDataFromEditItem = (data, command) => {
	
	// 		var newInventory = this.state.storeDetail.inventory;
	// 		if(command === 'save'){
	// 			newInventory[data.key] = {
	// 				name: data.name,
	// 				attributes: data.attributes || '', 
	// 				price : data.price,
	// 				brand : data.brand || '',
	// 				quantity : data.quantity,
	// 				description : data.description || '',
	// 				image : data.image || ''
	// 			}
	// 		}
	// 		else if(command === 'delete'){
	// 			newInventory.splice(data.key, 1);
	// 		}
		
	// 		//Set the new state of inventory and reset newItem 
	// 		this.setState((prevState)=>({
	// 			...prevState,
	// 			storeDetail : {
	// 				...prevState.storeDetail,
	// 				inventory: newInventory
	// 			},
	// 			modalIsOpen: false	
	// 		}), () => {
	// 			//Push to Firestore
	// 			firebaseApp.firestore().collection('stores').doc(this.props.match.params.id).update({
	// 				inventory: this.state.storeDetail.inventory
	// 			}).then(()=>{
	// 				//Tell reducer
	// 				this.props.updateStore(this.state.storeID, this.state.storeDetail);
					
	// 			}).catch((error)=>{
	// 				this.setState(()=>({
	// 					error : true,
	// 					errorMessage : error
	// 				}))
	// 			});
	// 		});
	// 	}
	// 	handleDataFromEditStore = (data) => {
	// 		this.setState(()=>({
	// 			storeDetail : {
	// 				...data
	// 			},
	// 			modalIsOpen : false
	// 		}), () => {
	// 			updateCollection('stores', this.props.match.params.id, {
	// 				address : data.address,
	// 				deliveroourl: data.deliveroourl,
	// 				description : data.description,
	// 				name : data.name,
	// 				opentableurl : data.opentableurl,
	// 				telephone : data.telephone,
	// 				url : data.url
	// 			})
	// 			.then(() => {
	// 				//Update store
	// 				this.props.updateStore(this.state.storeID, this.state.storeDetail);
	// 				console.log("Updated store info!");
	// 			}).catch((error)=>{
	// 				this.setState(()=>({
	// 					error : true,
	// 					errorMessage : error
	// 				}))
	// 			});
	// 		})
	// 	}
	
	// 	handleDataFromDeleteStore = (command) => {
	// 		if(command === 'delete'){
	// 			firebaseApp.firestore().collection('stores').doc(this.props.match.params.id).delete().then(()=>{
	// 				console.log("Store deleted");
	// 			}).then(()=>{
	
	// 				if(this.props.user.subscribed){
	// 					axios.post(SERVER_URL + '/update-subscription', {
	// 						subscriptionID : this.props.user.subscriptionID,
	// 						quantity : this.props.stores.length - 1
	// 					}).then((response)=>{
	// 						console.log(response);
	// 					});
	// 				}
	
	// 				//Reset Redux
	// 				this.props.resetStore();
	
	// 				//Call data again
	// 				firebaseApp.firestore().collection('stores').where("associatedID", "==", this.props.user.associatedID).get().then((snapshot)=>{
	// 					snapshot.docs.forEach(doc => {
	// 						let store = doc.data();
							
	// 						//You have to pull the document id from doc, not doc.data()
	// 						store.id = doc.id;
							
	// 						//Tell Redux
	// 						this.props.addStore(store);
	// 					});
	// 				}).then(()=>{
	// 					history.push('/my-stores');
	// 				});
	// 			});
	// 		}
	// 		else if(command === 'cancel'){
	// 			this.setState({modalIsOpen: false});
	// 		}
	// 	}
	
	// 	openModal = () => {
	// 		this.setState({modalIsOpen: true});
	// 	}
	// 	openAddItemModal = () => {
	// 		this.setState(()=>({
	// 			editStore : false,
	// 			addItem : true,
	// 			editItem : false,
	// 			uploadViaCSV : false,
	// 			modalIsOpen: true,
	// 			deleteStore : false
	// 		}));
	// 	}
	// 	openEditItemModal = (itemToEdit) => {
	// 		/* 
	// 			Set the 
	// 		*/
	// 		this.setState(()=> ({
	// 			itemToEdit,
	// 			modalIsOpen : true,
	
	// 			editStore : false,
	// 			addItem : false,
	// 			editItem : true,
	// 			uploadViaCSV : false,
	// 			deleteStore : false
	// 		}));
	// 	}
	// 	openCSVUploadModal = () => {
	// 		/* 
	// 			Set the 
	// 		*/
	// 		this.setState(()=> ({
	// 			modalIsOpen : true,
	
	// 			editStore : false,
	// 			addItem : false,
	// 			editItem : false,
	// 			uploadViaCSV : true,
	// 			deleteStore : false
	// 		}));
	// 	}
	// 	closeModal = () => {
	// 		this.setState({
	// 			modalIsOpen: false,
	// 		});
	// 	}
	// 	editStoreInfo = () => {
	// 		this.setState(()=> ({
	// 			modalIsOpen : true,
	// 			editStore : true,
	// 			addItem : false,
	// 			editItem : false,
	// 			uploadViaCSV : false,
	// 			deleteStore : false
	// 		}));
	// 	}
	
	// 	deleteStore = () => {
	// 		this.setState(()=>({
	// 			modalIsOpen : true,
	// 			editStore : false,
	// 			addItem : false,
	// 			editItem : false,
	// 			uploadViaCSV : false,
	// 			deleteStore : true
	// 		}))
	// 	}
	
	//   	componentDidMount(){
	// 		// Grab store == this ID in URL, && associatedID to store == ID in URL
	// 		for(var x = 0; x<this.props.stores.length; x++){
	// 			if(this.props.stores[x].id == this.props.match.params.id){
	// 				this.setState({
	// 					storeID : x,
	// 					storeDetail : this.props.stores[x]
	// 				});
	// 			}
	// 		}
	// 	}
	// 	closeError = () => {
	// 		this.setState((prevState)=>({
	// 			...prevState,
	// 			error: false
	// 		}));
	// 	}  
	// 	handleCSVUpload = (newInventory) => {
	// 		this.setState((prevState)=>({
	// 			...prevState,
	// 			storeDetail : {
	// 				...prevState.storeDetail,
	// 				inventory : newInventory
	// 			},
	// 			modalIsOpen : false
	// 		}), () => {
	// 			firebaseApp.firestore().collection('stores').doc(this.props.match.params.id).update({
	// 				inventory: this.state.storeDetail.inventory
	// 			}).then(()=>{
	// 				// Confirm with success message
	// 				console.log("Pushed to firebase!");
	// 			});
	// 		});
	// 	}
	// 	onSpacingChange = (e) => {
	// 		const spacing = Number(e.target.value);
	// 		this.setState((prevState)=>({
	// 			...prevState,
	// 			filter : {
	// 				...prevState.filter,
	// 				firstIndex : 0,
	// 				spacing : spacing
	// 			}
	// 		}), ()=> {
	// 			console.log(this.state.filter);
	// 		});
	// 	}
	// 	next = () => {
	// 		this.setState((prevState)=>({
	// 			...prevState,
	// 			filter : {
	// 				...prevState.filter,
	// 				firstIndex : prevState.filter.firstIndex + prevState.filter.spacing
	// 			}
	// 		}), ()=> {
	// 			console.log(this.state.filter);
	// 		});
	// 	}
	// 	previous = () => {
	// 		this.setState((prevState)=>({
	// 			...prevState,
	// 			filter : {
	// 				...prevState.filter,
	// 				firstIndex : prevState.filter.firstIndex - prevState.filter.spacing
	// 			}
	// 		}), () => {
	// 			console.log(this.state.filter);
	// 		});
	// 	}
	//   	render() {
	// 		const inventoryTable = this.state.storeDetail.inventory.slice(this.state.filter.firstIndex, (this.state.filter.firstIndex + this.state.filter.spacing)).map((item, i) => (
	// 			<InventoryTableRow
	// 				callback={this.openEditItemModal}
	// 				key={i}
	// 				position={i}
	// 				data={item}
	// 				name={item.name}
	// 				attributes={item.attributes}
	// 				price={item.price}
	// 				brand={item.brand}
	// 				quantity={item.quantity}
	// 				image={item.image}/>
	// 		));
	
	//     	return (
	//       		<div className="view-store">
	// 				{
	// 					this.state.error ? 
	// 					<div className="error-wrapper">
	// 						<span className="error-message">{this.state.errorMessage}</span>
	
	// 						<button className="error-close" onClick={this.closeError}>
	// 							<img src="/images/icons/cross.svg" alt=""/>
	// 						</button>
	// 					</div>  : ''					
	// 				}
	
	// 				<Modal
	// 					isOpen={this.state.modalIsOpen}
	// 					onAfterOpen={this.afterOpenModal}
	// 					onRequestClose={this.closeModal}
	// 					contentLabel="Example Modal"
	// 					style={customStyles}>
	
	// 						{this.state.editItem ? 
	// 						<EditItemForm 
	// 							callback={this.handleDataFromEditItem} 
	// 							itemToEdit={this.state.itemToEdit} /> : '' }
	
	// 						{this.state.addItem ?
	// 						<AddItemForm callback={this.handleDataFromAddItem}/> : ''} 
							
	// 						{this.state.editStore ? 
	// 						<EditStoreModal callback={this.handleDataFromEditStore} 
	// 							shopToEdit={this.state.storeDetail}/> : ''}
	
	// 						{this.state.uploadViaCSV ? 
	// 						<CSVUploadModal callback={this.handleCSVUpload}
	// 							inventory={this.state.storeDetail.inventory}/> : ''}
	
	// 						{this.state.deleteStore ? 
	// 							<DeleteStoreForm callback={this.handleDataFromDeleteStore}/> : ''
	// 						}	
							
	// 				</Modal>
	
	// 				<div className="controls-info-wrapper">
	// 					<div className="store-info">
	// 						<span>{this.state.storeDetail.name},<br/> {this.state.storeDetail.address.formatted_address}</span>
	// 						<button className="editButton" onClick={this.editStoreInfo}>
	// 							<img src="/images/icons/edit-icon.svg" alt=""/>
	// 							Edit
	// 						</button>
	// 						<button className="deleteButton" onClick={this.deleteStore}>
	// 							<img src="/images/icons/trash-icon.svg" alt=""/>
	// 							Delete
	// 						</button>
	// 					</div>
	
	// 					<div className="controls">
	// 						<button onClick={this.openAddItemModal}>
	// 							<img src="/images/icons/add-item-icon.svg" alt=""/>
	// 							Add Item
	// 						</button>
	// 						{/*<button onClick={this.exportTest}>
	// 							<img src="/images/icons/export-icon.svg" alt=""/>
	// 							Export
	// 						</button>*/}
	// 						<button onClick={this.openCSVUploadModal}>
	// 							<img src="/images/icons/upload-icon.svg" alt=""/>
	// 								Upload via CSV
	// 						</button>
	// 					</div>
	// 				</div>
	
	// 				<div className="table-title">
	// 					<h2>Products &amp; Services</h2>
	// 				</div>
	
	// 				<table className="inventory-table">
	// 					<thead>
	// 						<tr>
	// 							<th>Name</th>
	// 							<th>Attributes</th>
	// 							<th>Price</th>
	// 							<th>Brand</th>
	// 							<th>Quantity</th>
	// 							<th>Image</th>
	// 						</tr>
	// 					</thead>
	// 					<tbody>
	// 						{inventoryTable}
	// 					</tbody>
	// 				</table>
	// 				<div className="inventory-table-footer">
	// 					<div className="showing-wrapper">
	// 						<label htmlFor="showing">Showing</label>
	// 						<select 
	// 							name="showing" 
	// 							value={this.state.filter.spacing}
	// 							onChange={this.onSpacingChange}
	// 							id="">
	// 							<option value="25">25</option>
	// 							<option value="50">50</option>
	// 							<option value="100">100</option>
	// 						</select>
	// 					</div>
						
	// 					<div className="next-prev-wrapper">
	// 						<button 
	// 							className="previous" 
	// 							onClick={this.previous}>
	// 								Previous
	// 						</button>
	// 						<button 
	// 							className="next"
	// 							onClick={this.next}>
	// 								Next
	// 						</button>
	// 					</div>
	// 					<div>
	// 						<span>Showing {this.state.filter.firstIndex}-{this.state.filter.firstIndex + this.state.filter.spacing}</span>
	// 					</div>
	// 				</div>	
	//       		</div>
	//     	);
	//   	}
	// }
	// const mapDispatchToProps = (dispatch) => ({
	// 	addStore: (store) => dispatch(addStore(store)),
	// 	updateStore : (id, store) => dispatch(updateStore(id, store)),
	// 	resetStore: () => dispatch(resetStore())
	// });
	
	// const mapStateToProps = (state) => {
	// 	return {
	// 		user : state.userReducer,
	// 		stores : state.storesReducer
	// 	}
	// };
	
	
	// export default connect(mapStateToProps, mapDispatchToProps)(Store);
	