import React from 'react';
import {Link} from 'react-router-dom';

import AdSiteHeader from '../header/AdSiteHeader';
import Footer from '../footer/Footer';

const SixTips = () => {
	return(
		<div>
			<AdSiteHeader/>
			<div className="blog-content blog">
				<div className="blog-hero">
					<h1>6 tips to drive business back to your brick and mortar store after the coronavirus</h1>
				</div>
				<div className="blog-text">
					<h2>Simple solutions to wild times.</h2>

					<img src="/images/blog/5-tips-image.jpg" width="100%" alt=""/>

					<h3>Social brand presence</h3>
					<p>
						I’ll give you the same advice my mother gave me when I was 13 - “you’re probably cooler than you think”, so let your customers see that! If you’re a small cafe in Cornwall, or a women’s clothes store in Reading - your customers are your customers because they buy into that. 
						In 2019 Top10E-Commerce trends marked “The rise of the ethical consumer” as the 1 most important commerce trend. The modern consumer is more empowered than ever to ask questions about the brands they buy from - <br/>
						“What’s the environmental impact of my purchase?” <br/>
						“Am I buying from a mega-corp?” <br/>
						“What brands align with my values?”
					</p>

					<h3>Buy online, pick up in-store</h3>
					<p>Setting up an e-store has never been easier. Sites like SquareSpace and Shopify have built extremely easy to use software so that anyone can get setup with an online store. This doesn’t mean you have to become Deliveroo and get things into people laps in 20 minutes. A simple buy online pick up in store offer will ensure social distancing while keeping you running. Don't be afraid to lemonade stand it too - a simple setup outside your building to interface with customers will help to keep things going.</p>

					<h3>Delivery by yourself</h3>
					<p>
						You’ll be surprised how forgiving consumers can be in hard times - especially for small businesses. Not everything has to be ‘Get it by tomorrow’ and your customers will be ok with that. Teri Ellington of <a href="https://ellingtontimepiece.com/" target="_blank">Ellington Timepiece</a> took to LinkedIn to announce she was limiting her delivery days to Tuesday and Friday. The response she received was positive resulting in both forgiving customers and 
					</p>

					<h3>Opening hours for the at-risk / staggered opening times</h3>
					<p>It’s easy to think about things from the business’s perspective; “how can we get consumers into our store?”. It’s important to also reverse the lens to mitigate risk - “why would consumers not want to come in-store?”. The biggest threat right now to consumers is risk, “would I be putting myself in harms way?”. Supermarkets offered early shopping windows for key workers and the elderly and you can do the same. Alternatively, communicate when your store is or isn’t busy, so those at risk can plan accordingly.
					</p>

					<h3>Adjusting your offering</h3>
					<p>Some businesses have found success in the adjusting how they sell what they have. Restaurants have found success by seeing that they can’t create meals for customers inside so <a href="https://www.standard.co.uk/go/london/restaurants/detox-kitchen-closed-food-boxes-ingredients-supplier-coronavirus-a4392181.html">selling the raw ingredients to consumers to cook at home became the next best thing.</a></p>

					<p>Jacqui Ma from Goodordering has used her resources of making cycling accessories <a href="https://goodordering.com/collections/face-masks" target="_blank">to create stylish face masks.</a> She has also reported higher sales than ever, even compared to pre-coronavirus.</p>
					
					<h3>Join Peruse</h3>
					<p>We’d be stupid not to mention us! Our software allows shoppers to search exactly what they need, exactly where they need it. Any business can join us for free and so the next time a shopper thinks “where can I buy…” they know about you through Peruse!</p>

					<Link to="/signup" className="cta">
						<div className="cta-wrapper cta-black">
							<span>join us</span>
						</div>
					</Link> 
				</div>
			</div>
			<Footer/>
		</div>
)};

export default SixTips;