import React, {useEffect} from 'react';
import {Link} from 'react-router-dom';
// import Header from '../header/Header';
import Footer from '../footer/Footer';
import AdSiteHeader from '../header/AdSiteHeader';

const AdSite = () => {

	var shoppingDifferences = [{
        google: "Gym", 
        peruse : "Kettlebell personal training"
    },
    {
        google: "Fish Restaurant",
        peruse: "Scallop linguine"
    },
    {
        google : "Tech shop",
        peruse: "iPhone charger"
    },
    {
        google : "Clothes shops",
        peruse: "Blue jeans 34x34"
    },
    {
        google : "Grocery Store",
        peruse: "Peanut butter"
    },
    {
        google : "Hairdressers",
        peruse: "Women's hair highlights"
    },
    {
        google : "Barbers",
        peruse: "Mens cut throat shave"
    },
    {
        google : "Pub",
        peruse: "Brewdog IPA"
    },
    {
        google : "Sports shop",
        peruse: "Protein shaker"
    },
    {
        google : "Athletics store",
        peruse: "Running shorts"
    }];

    // var previousNumber;
	useEffect(()=>{
		var myInt = setInterval(() => {
			document.getElementById("googleSearch").innerHTML = "";
			document.getElementById("peruseSearch").innerHTML = "";
			var i = 0;
			var j = 0;
	
			var x = Math.floor(Math.random() * shoppingDifferences.length);
	
			// if(x == previousNumber){
			//     x = Math.floor(Math.random() * shoppingDifferences.length);
			// }
			// else{
	
			// }
	
			var googleText = shoppingDifferences[x].google; /* The text */
			var peruseText = shoppingDifferences[x].peruse;
			var speed = 50; /* The speed/duration of the effect in milliseconds */
	
			
	
			function googleWriter() {
				if (i < googleText.length) {
					document.getElementById("googleSearch").innerHTML += googleText.charAt(i);
					i++;
					setTimeout(googleWriter, speed);
				}
			}
			function peruseWriter() {
				if (j < peruseText.length) {
					document.getElementById("peruseSearch").innerHTML += peruseText.charAt(j);
					j++;
					setTimeout(peruseWriter, speed);
				}
			}
			peruseWriter();
			googleWriter();
			// previousNumber = x;
		}, 4500);

		var isInViewport = function (elem) {
			var bounding = elem.getBoundingClientRect();
			return (
				bounding.top >= 0 &&
				bounding.left >= 0 &&
				bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
				bounding.right <= (window.innerWidth || document.documentElement.clientWidth)
				);
			};
		
			var isScrolling;
		
			var image = document.getElementById('facts');

			var myScrollEvent = (event) => {
				// Clear our timeout throughout the scroll
				window.clearTimeout(isScrolling);
		
				// Set a timeout to run after scrolling ends
				isScrolling = setTimeout(function() {
					if (isInViewport(image)) {
						//Nest the timer functions so it does one then the other
						setTimeout(function(){
							document.getElementById('second-tile').style.opacity = 1;
							setTimeout(function(){
								document.getElementById('third-tile').style.opacity = 1;
							},2000);
						},300);                 
					}
				}, 66);
			}

			window.addEventListener('scroll', myScrollEvent, false);

		//The returned function is what to do when the component dismounts,
		//we remove the interval and scroll function so that it doesnt
		//go to other pages.
		return ()=>{
			clearInterval(myInt);
			window.removeEventListener('scroll', myScrollEvent)
		}
	},[])
    


	return(<div className="ad-site">
		<AdSiteHeader/>
		<section className="hero-container">
			<div className="purple-background"></div>
				<div className="hero-content">
					<div className="left-text">
						<h1>We're changing local shopping. <span>For good.</span></h1>
						<hr/>
						<p>
							Get found. Increase footfall. Increase sales.
						</p>
						<p>
							Peruse helps you do all that, for free.
						</p>
						<Link to="/signup" className="cta">
							<div className="cta-wrapper">
								<span>sign up</span>
							</div>
						</Link> 
					</div>
					<figure className="image-container">
						<div className="phone-wrapper">
							<div className="google-phone-wrapper">
								<div className="google-search-container-wrapper">
									<div className="google-search-container">
										<span className="google-search" id="googleSearch">
											Grocery Store
										</span>
									</div>
								</div>
								<img 
									srcset="./images/googlephone.png 1x, ./images/googlephone@2x.png 2x" 
									src="./images/googlephone.png" 
									alt="google phone"/>
							</div>  
							<div className="peruse-phone-wrapper">
								<div className="peruse-search-container-wrapper">
									<div className="peruse-search-container">
										<span className="peruse-search" id="peruseSearch">
											Ketchup
										</span>
									</div>
								</div>
								<img
									srcset="./images/hi-fiperuse.png 1x, ./images/hi-fiperuse@2x.png 2x" 
									src="./images/googlephone.png" 
									alt="google phone"/>
							</div>
						</div>    
						<div className="image-text">
							<p>Shoppers are finally searching with precision</p>
						</div>
					</figure>
				</div>
			</section>
			
			<section className="basic-explanation">
				<div className="explanation-content">
					<div className="explanation-text">
						<h2>Wait, what is it?</h2>
						<ol>
							<li>Businesses list their products and services on our platform, totally free.</li>
							<li>Shoppers use the Peruse app to shop locally.</li>
							<li>We show local businesses that have what they need.</li>
						</ol>
						
					</div>
					<div className="explanation-image-container">
						<figure className="explanation-image-wrapper">
							<img
								srcset="./images/smalliphone.png 1x, ./images/smalliphone@2x.png 2x" 
								src="./smalliphone.png" 
								alt="iphone with Peruse"/>
							<img
								srcset="./images/largeiphone.png 1x, ./images/largeiphone@2x.png 2x" 
								src="./images/largeiphone.png" 
								alt="iphone with Peruse"/>
						</figure>
					</div>
				</div>
			</section>
			
			<section className="overview">
				<figure className="overview-image-container">
					<img
						srcset="./images/overview.png 1x, ./images/overview@2x.png 2x" 
						src="./images/overview.png"  
						alt="how peruse users search"/>
				</figure>
				<div className="overview-text">
					<h2>Shoppers search their <span>local area</span> for <span>exactly</span> what they need</h2>
					<p>then we show them businesses like yours</p>
				</div>
			</section>

			<section className="pay-to-win">
				<div className="ptw-content">
					<div className="ptw-text">
						<h2>Results are shown by distance only</h2>
						<p>
							We display results by <span>distance only.</span><br/>
							If your shop is the closest - they see you first.
						</p>
						<p>With us, nobody can pay to win.</p>
					</div>
					<figure className="ptw-image">
						<img
							srcset="./images/ptw.png 1x, ./images/ptw@2x.png 2x" 
							src="./images/ptw.png" 
							alt="how results look"/>
					</figure>
				</div>
			</section>

			<section className="shopping-facts">
				<div className="shopping-content">
					<div className="shopping-text">
						<h2>Don't people just buy online now?</h2>
						<p>Shoppers go in-store when;</p>
						<ol>
							<li>They need something right away</li>
							<li>They want to see something in person first.</li>
							<li>They don't want to wait for online delivery.</li>
							<li>They want to 'shop ethically'</li>
						</ol>
						<p>Join us and help them find you.</p>
					</div>
					<div className="facts-stack" id="facts">
						<div className="tile first-tile" id="first-tile">
							<div className="tile-content">
								<h3>&ldquo;The rise of the ethical consumer&rdquo;</h3>
								<div className="source">
									<Link to="https://2019.10ecommercetrends.com/" target="_blank">
										10ECommerceTrends
									</Link>
								</div>
							</div>
						</div>
						<div className="tile second-tile" id="second-tile">
							<div className="tile-content">
								<h3>&ldquo;Consumers spend more in-store&rdquo;</h3>
								<p>Shoppers spend more per visit in-store than online.</p>
								<div className="source">
									<Link to="https://www.forbes.com/sites/gregpetro/2019/03/29/consumers-are-spending-more-per-visit-in-store-than-online-what-does-this-man-for-retailers/#e2c0ab975432" target="_blank">
										Forbes
									</Link>
								</div>
							</div>
						</div>
						<div className="tile third-tile" id="third-tile">
							<div className="tile-content">
								<p>
									&ldquo;nearly 80% of shoppers go in-store when there is an item they need or want immediately&rdquo;
								</p>
								<div className="source">
									<Link to="https://www.thinkwithgoogle.com/consumer-insights/online-to-offline-marketing/" target="_blank">
										Google
									</Link> 
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>

			<section className="why-free">
				<div className="why-free-content">
					<h2>Did we mention it's free?</h2>
					<p>
						Every now and then, we’ll show <br/> shoppers an advert.<br/>
					</p>
					<p>That’s how we pay the bills.</p>
				</div>
			</section>

			<section className="integrations">
				<div className="integrations-content">
					<div className="integration-text">
						<h2>Not another place to manage inventory!?</h2>
						<p>
							Easy export with major online sales software, plus we’ll check in every now and then to see what’s changed and update it for you <br/> 
							<span>- that's free too.</span>
						</p>
					</div>
					<figure className="integration-image">
						<img
							srcset="./images/shopify-woo.png 1x, ./images/shopify-woo@2x.png 2x" 
							src="./images/shopify-woo.png" 
							width="469px" 
							alt="online shopping brands we can import from"/>
					</figure>
				</div>
			</section>

			<section className="app-store-links">
				<div className="app-store-links-content">
					<h2>Go ahead. See for yourself.</h2>
					<p>We'll be here when you get back.</p>
					<figure className="badges">
						<Link to="https://play.google.com/store/apps/details?id=com.perusehq.peruse" target="_blank">
							<img 
								src="./images/google-play-badge.png" 
								height="68px" 
								alt="google play badge"/>
						</Link>
						<Link to="https://apps.apple.com/gb/app/peruse/id1482412969" target="_blank">
							<img 
								src="./images/app-store.png" 
								height="68px" 
								alt="apple app store badge"/>    
						</Link> 
					</figure>
				</div>
			</section>

			<section className="final-cta">
				<div className="final-cta-content">
					<h2>Yep. We're really proud of it.</h2>

					<p>
						After you make an account we'll give you a call within 48 hours,
						just to make sure everything's going smoothly.
					</p>

					<p className="bold">
						Welcome to the band
					</p>

					<Link to="/signup" className="cta">
						<div className="cta-wrapper cta-black">
							<span>Join</span>
						</div>
					</Link>
				</div> 
			</section>
			<Footer/>
		</div>
	)
}

export default AdSite;


