import React from 'react';
import {connect} from 'react-redux';

const InsightsMain = (props) => {

	document.title = "Peruse | Insights";

	return (
		<div>
			<div className="insights-wrapper">
				<div className="advert">
					<h3>
						We're gathering search and shopping data! When we're finished building we'll provide you with insights into local shopping and search
					</h3>
					<h4>Just sit tight!</h4>
				</div>
			</div>
		</div>
 	);
}

const mapStateToProps = (state) => {
	return {
		user : state.userReducer,
		stores : state.storesReducer
	}
};

export default connect(mapStateToProps, undefined)(InsightsMain);
