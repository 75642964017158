import React, {useState} from 'react';
import moment from 'moment';
import validator from 'validator';

const alphaAndSpacesRegex = '^[a-zA-Z ]*$';

const ChangePersonalDetails = () => {
	const [firstName, setFirstName] = useState("");
	const [lastName, setLastName] = useState("");
	const [dob, setDob] = useState({
		day: "01",
		month: "01",
		year: "1990"
	});
	const [phone, setPhone] = useState("");
	const [error, setError] = useState({
		firstname: false,
		lastname: false,
		dob: false,
		phone: false
	});


	const submitUserDetails = () => {

		const dob = moment(`${userDob.day}/${userDob.month}/${userDob.year}`, "DD/MM/YYYY").valueOf();

		console.log(dob);

		// const updatedDetails = {
		// 	firstname: firstName,
		// 	lastname: lastName,
		// 	dob: dob,
		// 	phone: phone
		// }
		//If no error, send the new details to the Settings callback
		// if (!this.state.error) {
		// 	this.props.callback(updatedDetails);
		// }
	}

	return (
		<div className="your-details-wrapper">

			<h3>Your Details</h3>

			<p>Remember these aren't the details of any of your stores, these are your personal details.</p>

			<label htmlFor="first-name">First Name</label>
			<input type="text"
				name="first-name"
				className="text-input"
				value={firstName}
				onChange={(e)=>setFirstName(e.target.value)}
			/>
			{
				error.firstname ?
					<span className="input-error">
						First names must only contain letters and spaces
					</span>
				: ''
			}

			<label htmlFor="last-name">Last Name</label>
			<input type="text"
				name="last-name"
				className="text-input"
				value={lastName}
				onChange={(e)=>setLastName(e.target.value)}
			/>
			{
				error.lastname ?
				<span className="input-error">Last names must only contain letters and spaces</span>
				: ''
			}

			<label htmlFor="dob-day">Date of Birth</label>
			<div className="date-input-wrapper">

				{/* Note the collapse */}
				<select name="day"
					value={dob.day}
					onChange={(e)=>setDob({
						...dob,
						day: e.target.value
					})}
					id="quantity">
					<option value="01">01</option>
					<option value="02">02</option>
					<option value="03">03</option>
					<option value="04">04</option>
					<option value="05">05</option>
					<option value="06">06</option>
					<option value="07">07</option>
					<option value="08">08</option>
					<option value="09">09</option>
					<option value="10">10</option>
					<option value="11">11</option>
					<option value="12">12</option>
					<option value="13">13</option>
					<option value="14">14</option>
					<option value="15">15</option>
					<option value="16">16</option>
					<option value="17">17</option>
					<option value="18">18</option>
					<option value="19">19</option>
					<option value="20">20</option>
					<option value="21">21</option>
					<option value="22">22</option>
					<option value="23">23</option>
					<option value="24">24</option>
					<option value="25">25</option>
					<option value="26">26</option>
					<option value="27">27</option>
					<option value="28">28</option>
					<option value="29">29</option>
					<option value="30">30</option>
					<option value="31">31</option>
				</select>

				<select name="month"
					value={dob.month}
					onChange={(e)=>setDob({
						...dob,
						month: e.target.value
					})}
					id="">
					<option value="01">January</option>
					<option value="02">February</option>
					<option value="03">March</option>
					<option value="04">April</option>
					<option value="05">May</option>
					<option value="06">June</option>
					<option value="07">July</option>
					<option value="08">August</option>
					<option value="09">September</option>
					<option value="10">October</option>
					<option value="11">November</option>
					<option value="12">December</option>
				</select>

				<select name="dob-year"
					value={dob.year}
					onChange={(e)=>setDob({
						...dob,
						year: e.target.value
					})}>
					<option value="">Year</option>
					<option value="2012">2012</option>
					<option value="2011">2011</option>
					<option value="2010">2010</option>
					<option value="2009">2009</option>
					<option value="2008">2008</option>
					<option value="2007">2007</option>
					<option value="2006">2006</option>
					<option value="2005">2005</option>
					<option value="2004">2004</option>
					<option value="2003">2003</option>
					<option value="2002">2002</option>
					<option value="2001">2001</option>
					<option value="2000">2000</option>
					<option value="1999">1999</option>
					<option value="1998">1998</option>
					<option value="1997">1997</option>
					<option value="1996">1996</option>
					<option value="1995">1995</option>
					<option value="1994">1994</option>
					<option value="1993">1993</option>
					<option value="1992">1992</option>
					<option value="1991">1991</option>
					<option value="1990">1990</option>
					<option value="1989">1989</option>
					<option value="1988">1988</option>
					<option value="1987">1987</option>
					<option value="1986">1986</option>
					<option value="1985">1985</option>
					<option value="1984">1984</option>
					<option value="1983">1983</option>
					<option value="1982">1982</option>
					<option value="1981">1981</option>
					<option value="1980">1980</option>
					<option value="1979">1979</option>
					<option value="1978">1978</option>
					<option value="1977">1977</option>
					<option value="1976">1976</option>
					<option value="1975">1975</option>
					<option value="1974">1974</option>
					<option value="1973">1973</option>
					<option value="1972">1972</option>
					<option value="1971">1971</option>
					<option value="1970">1970</option>
					<option value="1969">1969</option>
					<option value="1968">1968</option>
					<option value="1967">1967</option>
					<option value="1966">1966</option>
					<option value="1965">1965</option>
					<option value="1964">1964</option>
					<option value="1963">1963</option>
					<option value="1962">1962</option>
					<option value="1961">1961</option>
					<option value="1960">1960</option>
					<option value="1959">1959</option>
					<option value="1958">1958</option>
					<option value="1957">1957</option>
					<option value="1956">1956</option>
					<option value="1955">1955</option>
					<option value="1954">1954</option>
					<option value="1953">1953</option>
					<option value="1952">1952</option>
					<option value="1951">1951</option>
					<option value="1950">1950</option>
					<option value="1949">1949</option>
					<option value="1948">1948</option>
					<option value="1947">1947</option>
					<option value="1946">1946</option>
					<option value="1945">1945</option>
					<option value="1944">1944</option>
					<option value="1943">1943</option>
					<option value="1942">1942</option>
					<option value="1941">1941</option>
					<option value="1940">1940</option>
					<option value="1939">1939</option>
					<option value="1938">1938</option>
					<option value="1937">1937</option>
					<option value="1936">1936</option>
					<option value="1935">1935</option>
					<option value="1934">1934</option>
					<option value="1933">1933</option>
					<option value="1932">1932</option>
					<option value="1931">1931</option>
					<option value="1930">1930</option>
					<option value="1929">1929</option>
					<option value="1928">1928</option>
					<option value="1927">1927</option>
					<option value="1926">1926</option>
					<option value="1925">1925</option>
					<option value="1924">1924</option>
					<option value="1923">1923</option>
					<option value="1922">1922</option>
					<option value="1921">1921</option>
					<option value="1920">1920</option>
				</select>
			</div>

			<label htmlFor="company">Phone</label>
			<input type="text"
				name="company"
				className="text-input"
				value={phone}
				onChange={(e)=>setPhone(e.target.value)}
				placeholder="0123456789" />
			
			{
				error.phone ?
				<span className="input-error">Phone number must contain numbers only</span>
				: ''
			}

			<button 
				onClick={submitUserDetails} 
				className="button save">
					Save My Details
			</button>
		</div>
	);
}

export default ChangePersonalDetails;


// onLastNameChange = (e) => {
	// 	const lastname = e.target.value;
	// 	console.log(lastname);
	// 	this.setState((prevState) => ({
	// 		...prevState,
	// 		lastname
	// 	}), () => {
	// 		if (!this.state.lastname || !validator.matches(this.state.lastname, alphaAndSpacesRegex)) {
	// 			this.setState((prevState) => ({
	// 				error: true,
	// 				errorCategory: {
	// 					...prevState.errorCategory,
	// 					lastname: true
	// 				}
	// 			}));
	// 		}
	// 		else {
	// 			this.setState((prevState) => ({
	// 				error: false,
	// 				errorCategory: {
	// 					...prevState.errorCategory,
	// 					lastname: false
	// 				}
	// 			}));
	// 		}
	// 	});
	// }


	// componentDidMount() {

	// 	// var date = moment(this.props.user.dob).format("DD/MM/YYYY");
	// 	// console.log(date);

	// 	// var day = moment(this.props.user.dob).format("DD");
	// 	// var month = moment(this.props.user.dob).format("MM");
	// 	// var year = moment(this.props.user.dob).format("YYYY");

	// 	// console.log(`${day}, ${month}, ${year}`);

	// 	this.setState(() => ({
	// 		...this.props.user,
	// 		userDob: {
	// 			day: moment(this.props.user.dob).format("DD"),
	// 			month: moment(this.props.user.dob).format("MM"),
	// 			year: moment(this.props.user.dob).format("YYYY")
	// 		}
	// 	}))
	// }
	

	// onPostcodeChange = (e) => {
	// 	const postcode = e.target.value;
	// 	console.log(postcode);

	// 	this.setState((prevState) => ({
	// 		...prevState,
	// 		address: {
	// 			...prevState.address,
	// 			postcode: postcode
	// 		}
	// 	}), () => {
	// 		if (!postcode || !validator.isPostalCode(postcode, 'GB')) {
	// 			this.setState((prevState) => ({
	// 				error: true,
	// 				errorCategory: {
	// 					...prevState.errorCategory,
	// 					addressPostcode: true
	// 				}
	// 			}));
	// 		}
	// 		else {
	// 			this.setState((prevState) => ({
	// 				error: false,
	// 				errorCategory: {
	// 					...prevState.errorCategory,
	// 					addressPostcode: false
	// 				}
	// 			}));
	// 		}
	// 	});
	// }