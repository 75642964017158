import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import {connect} from 'react-redux';
import moment from 'moment';
import validator from 'validator';

import {firebaseApp} from '../../firebase/firebase';
import {addUser} from '../../actions/userActions';
import {setLoading} from '../../actions/loadingActions';
import {resetStore} from '../../actions/storesActions';
import {SERVER_URL} from '../../../config/config';

const SignUp = (props) => {
	const alphaAndSpacesRegex = '^[a-zA-Z ]*$';
	document.title = "Peruse | Sign Up";
	
	/* Variables for form to control. Inputs, then their errors. */
	const [firstname, setFirstName] = useState("");
	const [lastname, setLastName] = useState("");
	const [dob, setDob] = useState({ day: "01", month: "01", year: "1980" });
	const [email, setEmail] = useState("");
	const [phone, setPhone] = useState("");
	const [password, setPassword] = useState("");
	const [confirmpassword, setConfirmPassword] = useState("");
	const [firstNameError, setFirstNameError] = useState({
		isError: false,
		message: ""
	});
	const [lastNameError, setLastNameError] = useState({
		isError: false,
			message: ""
	});
	const [emailError, setEmailError] = useState({
		isError: false,
		message: ""
	});
	const [phoneError, setPhoneError] = useState({
		isError: false,
		message: ""
	});
	const [passwordError, setPasswordError] = useState({
		isError: false,
		message: ""
	});
	const [firebaseError, setFirebaseError] = useState({
		isError: false,
		message: ""
	});

	/*Function to execute when user hit's Sign Up*/
	const submitSignUp = () => {

		//Reset firebase error
		setFirebaseError({
			isError: false,
			message: ""
		});

		//If there is an error with firstname
		if(!firstname || !validator.matches(firstname, alphaAndSpacesRegex)){
			setFirstNameError({
				isError : true,
				message : "First name must contain only letters and spaces"
			});
			window.scrollTo(0, 0);
		}else{
			setFirstNameError({
				isError : false,
				message : ""	
			});
		}
		
		//If there is an error with lastname
		if(!lastname || !validator.matches(lastname, alphaAndSpacesRegex)){
			setLastNameError({
				isError : true,
				message : "Last name must contain only letters and spaces"	
			});
			window.scrollTo(0, 0);
		}else{
			setLastNameError({
				isError : false,
				message : ""
			});
		}

		//If there is an error with email
		if(!email || !validator.isEmail(email)){
			setEmailError({
				isError : true,
				message : "Isn't a valid email"
			});
			window.scrollTo(0, 0);
		}else{
			setEmailError({
				isError : false,
				message : ""
			});
		}	

		//If there is an error with phone
		if(!phone || !validator.isNumeric(phone, {no_symbols: true})){
			setPhoneError({
				isError : true,
				message : "Phone number must contain numbers only"
			});
			window.scrollTo(0, 0);
		}else{
			setPhoneError({
				isError : false,
				message : ""
			});
		}

		//If there is an error with password
		if(confirmpassword !== password || password.length < 6){
			setPasswordError({
				isError : true,
				message : "Passwords must match and be longer than 6 characters"
			});
			window.scrollTo(0, 0);
		}else{
			setPasswordError({
				isError : false,
				message : ""
			});
		}

		//If no errors, execute submission
		if(!firstNameError.isError && !lastNameError.isError && !emailError.isError && !phoneError.isError && !passwordError.isError){
			//Get unix timestamp for date of birth
			const unixtimestamp = moment(`${dob.day}/${dob.month}/${dob.year}`, "DD/MM/YYYY").valueOf();

			//Reset redux so you dont get other people's data
			props.resetStore();

			//Create user in firebase
			firebaseApp.auth().createUserWithEmailAndPassword(email, password).then((UserCredential)=>{
				// Add a new document in collection "users"
				firebaseApp.firestore().collection("users").doc(UserCredential.user.uid).set({
					firstname,
					lastname,
					email,
					dob : unixtimestamp,
					associatedID : UserCredential.user.uid,
					subscribed : false,
					subscriptionID : "",
					subscriptionplan : {
						enterprise : false,
						pro : false,
						regular : false
					},
					phone,
					companyname : "",
					createdon : moment().valueOf()
				}).then(() => {
					//Put them in person reducer for global access
					props.addUser({
						firstname,
						lastname,
						email,
						dob,
						associatedID : UserCredential.user.uid,
						subscribed : false,
						subscriptionID : "",
						subscriptionplan : {
							enterprise : false,
							pro : false,
							regular : false
						},
						phone,
						companyname : ""
					});
						
					//Create user in stripe
					axios.post(SERVER_URL + '/create-customer', {
						name : `${firstname} ${lastname}`,
						associatedID : UserCredential.user.uid,
						email : email
					}).then(function(response){
						console.log(response);
					});
				}).then(() => {
					props.history.push('/my-stores');
				})
			}).catch(function(error) {
				setFirebaseError({
					isError: true,
					message: `${error.code}: ${error.message}`
				});
				console.log(error);
			});

		}
	};


	return (
		<div className="sign-up-container">

			<div className="sign-up-form">
				<h2>Business Sign Up</h2>
				<hr className="title-underline" />

				<Link to='/signin'>
					Got an account? Sign in
				</Link>

				{
					firstNameError.isError || lastNameError.isError || emailError.isError || phoneError.isError || passwordError.isError || firebaseError.isError ? 
					<div>
						<span className="input-error">{firstNameError.message}</span>
						<span className="input-error">{lastNameError.message}</span>
						<span className="input-error">{emailError.message}</span>
						<span className="input-error">{phoneError.message}</span>
						<span className="input-error">{passwordError.message}</span>
						<span className="input-error">{firebaseError.message}</span>
					</div> :
					''	
				}

				<div className="sign-up-inputs-wrapper">
					<div className="input-wrapper">
						<label htmlFor="firstname" className="labels">
							First Name
						</label>
						<input
							type="text"
							className="text-input"
							name="firstname"
							value={firstname}
							onChange={e => setFirstName(e.target.value)}
							placeholder="Sheryl"
						/>
					</div>

					<div className="input-wrapper">
						<label htmlFor="lastname" className="labels">
							Last Name
						</label>
						<input
							type="text"
							className="text-input"
							name="lastname"
							value={lastname}
							onChange={e => setLastName(e.target.value)}
							placeholder="Sandberg"
						/>
					</div>

					<div className="input-wrapper">
						<label htmlFor="day" className="labels">Date of Birth</label>
						<div className="date-input-wrapper">
							{/* Note the collapse */}
							<select name="day"
								value={dob.day}
								onChange={e => setDob({
									...dob,
									day: e.target.value,
								})}
								id="quantity">
								<option value="01">01</option>
								<option value="02">02</option>
								<option value="03">03</option>
								<option value="04">04</option>
								<option value="05">05</option>
								<option value="06">06</option>
								<option value="07">07</option>
								<option value="08">08</option>
								<option value="09">09</option>
								<option value="10">10</option>
								<option value="11">11</option>
								<option value="12">12</option>
								<option value="13">13</option>
								<option value="14">14</option>
								<option value="15">15</option>
								<option value="16">16</option>
								<option value="17">17</option>
								<option value="18">18</option>
								<option value="19">19</option>
								<option value="20">20</option>
								<option value="21">21</option>
								<option value="22">22</option>
								<option value="23">23</option>
								<option value="24">24</option>
								<option value="25">25</option>
								<option value="26">26</option>
								<option value="27">27</option>
								<option value="28">28</option>
								<option value="29">29</option>
								<option value="30">30</option>
								<option value="31">31</option>
							</select>

							<select name="dob-month"
								value={dob.month}
								onChange={e => {
									setDob({
										...dob,
										month: e.target.value
									})
								}}
								id="">
								<option value="01">January</option>
								<option value="02">February</option>
								<option value="03">March</option>
								<option value="04">April</option>
								<option value="05">May</option>
								<option value="06">June</option>
								<option value="07">July</option>
								<option value="08">August</option>
								<option value="09">September</option>
								<option value="10">October</option>
								<option value="11">November</option>
								<option value="12">December</option>
							</select>

							<select name="dob-year"
								value={dob.year}
								onChange={e => {
									setDob({
										...dob,
										year: e.target.value
									})
								}}>
								<option value="">Year</option>
								<option value="2012">2012</option>
								<option value="2011">2011</option>
								<option value="2010">2010</option>
								<option value="2009">2009</option>
								<option value="2008">2008</option>
								<option value="2007">2007</option>
								<option value="2006">2006</option>
								<option value="2005">2005</option>
								<option value="2004">2004</option>
								<option value="2003">2003</option>
								<option value="2002">2002</option>
								<option value="2001">2001</option>
								<option value="2000">2000</option>
								<option value="1999">1999</option>
								<option value="1998">1998</option>
								<option value="1997">1997</option>
								<option value="1996">1996</option>
								<option value="1995">1995</option>
								<option value="1994">1994</option>
								<option value="1993">1993</option>
								<option value="1992">1992</option>
								<option value="1991">1991</option>
								<option value="1990">1990</option>
								<option value="1989">1989</option>
								<option value="1988">1988</option>
								<option value="1987">1987</option>
								<option value="1986">1986</option>
								<option value="1985">1985</option>
								<option value="1984">1984</option>
								<option value="1983">1983</option>
								<option value="1982">1982</option>
								<option value="1981">1981</option>
								<option value="1980">1980</option>
								<option value="1979">1979</option>
								<option value="1978">1978</option>
								<option value="1977">1977</option>
								<option value="1976">1976</option>
								<option value="1975">1975</option>
								<option value="1974">1974</option>
								<option value="1973">1973</option>
								<option value="1972">1972</option>
								<option value="1971">1971</option>
								<option value="1970">1970</option>
								<option value="1969">1969</option>
								<option value="1968">1968</option>
								<option value="1967">1967</option>
								<option value="1966">1966</option>
								<option value="1965">1965</option>
								<option value="1964">1964</option>
								<option value="1963">1963</option>
								<option value="1962">1962</option>
								<option value="1961">1961</option>
								<option value="1960">1960</option>
								<option value="1959">1959</option>
								<option value="1958">1958</option>
								<option value="1957">1957</option>
								<option value="1956">1956</option>
								<option value="1955">1955</option>
								<option value="1954">1954</option>
								<option value="1953">1953</option>
								<option value="1952">1952</option>
								<option value="1951">1951</option>
								<option value="1950">1950</option>
								<option value="1949">1949</option>
								<option value="1948">1948</option>
								<option value="1947">1947</option>
								<option value="1946">1946</option>
								<option value="1945">1945</option>
								<option value="1944">1944</option>
								<option value="1943">1943</option>
								<option value="1942">1942</option>
								<option value="1941">1941</option>
								<option value="1940">1940</option>
								<option value="1939">1939</option>
								<option value="1938">1938</option>
								<option value="1937">1937</option>
								<option value="1936">1936</option>
								<option value="1935">1935</option>
								<option value="1934">1934</option>
								<option value="1933">1933</option>
								<option value="1932">1932</option>
								<option value="1931">1931</option>
								<option value="1930">1930</option>
								<option value="1929">1929</option>
								<option value="1928">1928</option>
								<option value="1927">1927</option>
								<option value="1926">1926</option>
								<option value="1925">1925</option>
								<option value="1924">1924</option>
								<option value="1923">1923</option>
								<option value="1922">1922</option>
								<option value="1921">1921</option>
								<option value="1920">1920</option>
								<option value="1919">1919</option>
								<option value="1918">1918</option>
								<option value="1917">1917</option>
								<option value="1916">1916</option>
								<option value="1915">1915</option>
								<option value="1914">1914</option>
								<option value="1913">1913</option>
								<option value="1912">1912</option>
								<option value="1911">1911</option>
								<option value="1910">1910</option>
								<option value="1909">1909</option>
								<option value="1908">1908</option>
								<option value="1907">1907</option>
								<option value="1906">1906</option>
								<option value="1905">1905</option>
								<option value="1904">1904</option>
								<option value="1903">1903</option>
								<option value="1902">1902</option>
								<option value="1901">1901</option>
								<option value="1900">1900</option>
							</select>
						</div>
					</div>

					<div className="input-wrapper">
					<label htmlFor="email" className="labels">
						Email
					</label>
					<input
						type="text"
						className="text-input"
						name="email"
						value={email}
						onChange={e => setEmail(e.target.value)}
						placeholder="your@email.com"
					/>
					</div>

					<div className="input-wrapper">
						<label htmlFor="phone" className="labels">
						Phone
						</label>
						<input
							type="text"
							className="text-input"
							name="phone"
							value={phone}
							onChange={e => setPhone(e.target.value)}
							placeholder="01234567890"
						/>
					</div>

					<div className="input-wrapper">
						<label htmlFor="password" className="labels">
						Password
						</label>
						<input
							type="password"
							className="text-input"
							name="password"
							value={password}
							onChange={e => setPassword(e.target.value)}
						/>
					</div>

					<div className="input-wrapper">
						<label htmlFor="confirmpassword" className="labels">
							Confirm Password
						</label>
						<input
							type="password"
							className="text-input"
							name="confirmpassword"
							value={confirmpassword}
							onChange={e => setConfirmPassword(e.target.value)}
						/>
					</div>
				</div>
				<div className="centered-button-container">
					<button className="button" 
						onClick={submitSignUp}>
							Sign Up
					</button>
				</div>

			</div>
		</div>
	);
}

/*
  This is used with connect()() to basically say
  Give the application MyStoreMain the properties mapDispatchToProps
  giving MyStoreMain the ability to say this.props.dispatch
  and then send something to the reducer

  Same for mapStateToProps, it's just saying let the component use
  this.props.stores which is data from the ReduxStore
*/
const mapDispatchToProps = (dispatch) => ({
	addUser: (store) => dispatch(addUser(store)),
	resetStore: () => dispatch(resetStore()),
	setLoading : (state) => dispatch(setLoading(state))
});
  
const mapStateToProps = (state) => {
	return {
		user : state.userReducer
	}
};
  
export default connect(mapStateToProps, mapDispatchToProps)(SignUp);