import React, {useState} from "react";
import { firebaseApp, updateCollection } from "../firebase/firebase";
import { connect } from "react-redux";

import ChangePassword from "./settings/ChangePassword";
import ChangePersonalDetails from "./settings/ChangePersonalDetails";
import ChangeEmail from './settings/ChangeEmail';
import {editUser} from '../actions/userActions';

const SettingsMain = (props) => {

	document.title = "Peruse | Settings";

	const [error, setError] = useState(false);
	const [errorMessage, setErrorMessage] = useState("");

	const passwordChangeCallback = (newPassword) => {
		//Update Firestore
		const user = firebaseApp.auth().currentUser;

		user.updatePassword(newPassword).then(function() {
			// Update successful.

		}).catch(function(error) {
			// An error happened.
			setErrorMessage(error);
		});
	};
	
    const changePersonalDetailsCallback = (data) => {
		//Tell Firestore
		console.log(data);
		updateCollection("users", props.user.associatedID, {...data}).then(()=>{
			//Have to double spread of original data and new data because update() doesn't return the doc.
			props.editUser({
				...props.user,
				...data
			});
		}).catch((err)=>{
			setErrorMessage(err)
			// console.log("There's been an error!" + err);
		});
	};
	
	const changeEmailCallback = (newEmail) => {
		const user = firebaseApp.auth().currentUser;

		user.updateEmail(newEmail).then(() => {
			// Update successful.
			console.log("Email updated!");

			updateCollection("users", props.user.associatedID, {email : newEmail})
			.then(()=>{
				props.editUser({
					...props.user,
					email : newEmail
				});
			});

		}).catch(function(error) {
			// An error happened.
			console.log("Didn't work!");
		});
	}


	return(
		<div>
			<div className="settings-wrapper">
				{
					error ? 
					<div className="error-wrapper">
						<span className="error-message">{errorMessage}</span>

						<button className="error-close" onClick={closeError}>
							<img src="/images/icons/cross.svg" alt="close"/>
						</button>
					</div>  : ''					
				}
				{/*<div id="loading-confirmation">
					<p>Tick</p>
				</div>*/}
				<ChangePersonalDetails
					user={props.user}
					callback={changePersonalDetailsCallback}
				/>
				<ChangePassword 
					callback={passwordChangeCallback} 
				/>
				<ChangeEmail 
					email={props.user.email}
					callback={changeEmailCallback}
				/>
			</div>	
		</div>
	);
}


const mapDispatchToProps = (dispatch) => ({
	editUser: (user) => dispatch(editUser(user))
});

const mapStateToProps = state => {
    return {
        user: state.userReducer,
        stores: state.storesReducer
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SettingsMain);