import React, {useState} from 'react';
import { v4 as uuid } from 'uuid';
import GooglePlacesAutocomplete, { geocodeByPlaceId } from 'react-google-places-autocomplete';
import axios from 'axios';
import { connect } from 'react-redux';

import { firebaseApp } from '../../firebase/firebase';
import { history } from '../../routers/AppRouter';
import { addStore } from '../../actions/storesActions';
import { SERVER_URL, FIREBASE_CONFIG } from '../../../config/config';

const NewStore = (props) => {
	
	const [name, setName] = useState("");
	const [address, setAddress] = useState({
		address_components : [],
		formatted_address : ""
	});
	const [url, setUrl] = useState("");
	const [telephone, setTelephone] = useState("");
	const [description, setDescription] = useState("");
	const [deliveroo, setDeliveroo] = useState(false);
	const [opentable, setOpentable] = useState(false);
	const [deliveroourl, setDeliveroourl] = useState("");
	const [opentableurl, setOpentableurl] = useState("");
	const [shopimage, setShopimage] = useState("");
	const [progress, setProgress] = useState(0);
	const [lat, setLat] = useState("");
	const [lng, setLng] = useState("");

	const [error, setError] = useState(false);
	const [errorMessage, setErrorMessage] = useState({
		title:"",
		message:""
	});

	const imageUpload = (e) => {

		var file = e.target.files[0];

		//Some characteristics one can access
		//file.type, file.size, file.lastModifiedDate		

		if (file.size > 1000000) {
			setError(true);
			setErrorMessage({
				title: "Image Error",
				message: "Image size too big, must be less than 1MB"
			});
		}
		else {
			const uniqueID = uuid();

			var storageRef = firebaseApp.storage().ref('shop-images/' + uniqueID);
			var task = storageRef.put(file);

			task.on('state_changed', (snapshot) => {
				var percentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
				this.setState(() => ({
					progress: percentage
				}))
			},(err) => {
				//If error.
				console.log(err);
			},() => {
				setError(false);
				setErrorMessage({});
				setShopimage(uniqueID);
				console.log("done uploading image");
			});
		}
	}

	const onSubmit = () => {
		firebaseApp.firestore().collection('stores').add({
			name,
			address,
			lat,
			lng,
			description,
			url,
			telephone,
			inventory : [],
			isShop : false,
			isService : false,
			opentableurl,
			deliveroourl,
			shopimage,
			associatedID: props.user.associatedID,
		}).then((document) => {
			console.log("All done baby!");
			props.addStore({
				associatedID: props.user.associatedID,
				id: document.id,
				name,
				address,
				lat,
				lng,
				description,
				url,
				telephone,
				inventory : [],
				isShop : false,
				isService : false,
				opentableurl,
				deliveroourl,
				shopimage,
			});
			props.history.push('/my-stores');
		}).catch(function (error) {
			setError(true);
			setErrorMessage(error)
			console.error("Error adding a document: ", error);
		});

	}

	return (
		<div className="new-store-form">
			{
				error ?
				<div className="error-box">
					<h1>{errorMessage.title}</h1>
					<p>{errorMessage.message}</p>
				</div> : ''
			}

			<label className="labels" htmlFor="shop-name">Shop Name</label>
			<input name="shop-name"
				className="text-input"
				value={name}
				onChange={(e)=>setName(e.target.value)}
				type="text"
				placeholder="My Shop Name" />

			<div className="location-autocomplete-wrapper">
				<label className="labels" htmlFor="location">Location</label>
				<GooglePlacesAutocomplete
					autocompletionRequest={{
						componentRestrictions: {
							country: ['gb'],
						}
					}}
					placeholder="Start typing..."
					onSelect={(location) => {
						geocodeByPlaceId(location.place_id)
							.then(results => {
								console.log(results);

								setAddress({
									address_components: results[0].address_components,
									formatted_address: results[0].formatted_address
								});
								setLat(results[0].geometry.location.lat());
								setLng(results[0].geometry.location.lng());
							})
							.catch(error => console.error(error));
					}}
				/>
			</div>


			<div className="static-map">
				{
					lat && lng ?
						<img src={`https://maps.googleapis.com/maps/api/staticmap?center=${lat},${lng}
						&zoom=18&size=400x400&markers=color:red%7C${lat},${lng}
						&maptype=roadmap&key=AIzaSyBmLWvBZKV9tLodqe434j0oBiNpBCDCOos`} />
						:
						''
				}
			</div>

			<label className="labels" 
				id="description" 
				htmlFor="description">
					Description
				</label>
			<textarea name="description"
				className="text-input textarea"
				cols="30"
				rows="10"
				value={description}
				onChange={(e)=>setDescription(e.target.value)}
				placeholder="Tell the world about your store!">
			</textarea>

			<label className="labels" htmlFor="shop-url">Shop URL</label>
			<input name="shop-url"
				className="text-input"
				value={url}
				onChange={(e)=>setUrl(e.target.value)}
				type="text"
				placeholder="www.yourshopname.com" />

			<label className="labels" htmlFor="telephone">Telephone</label>
			<input name="telephone"
				className="text-input"
				value={telephone}
				onChange={(e)=>setTelephone(e.target.value)}
				type="text"
				placeholder="01234567890" />

			<label className="labels" htmlFor="deliveroo">Are you on Deliveroo?</label>
			<input type="radio"
				className="input-radio"
				name="deliveroo"
				value="true"
				onChange={(e)=>setDeliveroo(e.target.value)}
				checked={deliveroo === "true"} /> Yes
			<input type="radio"
				className="input-radio"
				name="deliveroo"
				value="false"
				onChange={(e)=>setDeliveroo(e.target.value)}
				checked={deliveroo === "false"} /> No

			{
				deliveroo === 'true' ?
					<div>
						<label className="labels" htmlFor="deliveroo-url">Deliveroo URL</label>
						<input name="deliveroo-url"
							className="text-input"
							type="text"
							value={deliveroourl}
							onChange={(e)=>setDeliveroourl(e.target.value)}
							placeholder="http://www.yourdeliverooURL.co.uk" />
					</div> : ''
			}

			<label className="labels" htmlFor="open-table">Are you on OpenTable?</label>
			<input type="radio"
				className="input-radio"
				name="open-table"
				onChange={(e)=>setOpentable(e.target.value)}
				value="true"
				checked={opentable == "true"} /> Yes
			<input type="radio"
				className="input-radio"
				name="open-table"
				onChange={(e)=>setOpentable(e.target.value)}
				value="false"
				checked={opentable == "false"} /> No

			{
				opentable === 'true' ?
					<div>
						<label className="labels" htmlFor="open-table-url">OpenTable URL</label>
						<input name="open-table-url"
							className="text-input"
							value={opentableurl}
							onChange={(e)=>setOpentableurl(e.target.value)}
							type="text"
							placeholder="https://www.opentable.co.uk/r/your-restaurant" />
					</div> : ''
			}

			<label className="labels" htmlFor="image-of-shop">Image of store</label>
			{
				shopimage ?
					<img src={"https://firebasestorage.googleapis.com/v0/b/" + FIREBASE_CONFIG.STORAGE_BUCKET + "/o/shop-images%2F" + shopimage + "?alt=media"} height="100px" alt="" />
					: ''
			}
			<input name="image"
				type="file"
				id="files"
				className="file-input"
				name="files[]"
				onChange={imageUpload} />
			<br />
			<progress value={progress} max="100" id="progress"></progress>
			<span className="image-file-size">No larger than 1MB</span>

			<button className="button save" onClick={onSubmit}>Save</button>
		</div>
	)
}

const mapDispatchToProps = (dispatch) => ({
	addStore: (store) => dispatch(addStore(store))
});

const mapStateToProps = (state) => {
	return {
		user: state.userReducer,
		stores: state.storesReducer
	}
};

export default connect(mapStateToProps, mapDispatchToProps)(NewStore);