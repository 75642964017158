// Inventory Reducer
const userReducerDefaultState = {};

export default (state = userReducerDefaultState, action) => {
	switch (action.type) {
		case 'ADD_USER':
			return action.user;
		case 'EDIT_USER':
			return action.user;
		case 'RESET_USER': 
			return []; 
		default:
			return state;
  	}
};