import React from 'react';
import {Link} from 'react-router-dom';

import AdSiteHeader from './header/AdSiteHeader';
import Footer from './footer/Footer';


const BlogMain = () => {

	document.title = "Peruse | Blog"

	return(
		<div className="blog">
			<AdSiteHeader/>
			<div className="blog-wrapper">
				<h1>Blog</h1>
				<section className="articles-container">
					<Link to="/blog/6-tips-to-drive-business-after-coronavirus"	className="article" 
					id="six-tips-drive-business">
						<div className="title">
							<h3>
								6 tips to drive business back to your store after the coronavirus
							</h3>
						</div>
					</Link>
				</section>
			</div>
			<Footer/>
		</div>
	);
} 

export default BlogMain;