// Inventory Reducer
const storesReducerDefaultState = [];

export default (state = storesReducerDefaultState, action) => {
	switch (action.type) {
    	case 'ADD_STORE':
      		return [
        		...state,
        		action.store
			];
		case 'UPDATE_STORE':
			state[action.id] = action.store; 
			return state;
		case 'UPDATE_INVENTORY':
			state[action.id].inventory = action.inventory;
			return state;
		case 'RESET_STORES':
			return [];
    	default:
      		return state;
  	}
};