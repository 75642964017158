import React from 'react';
import {Link} from 'react-router-dom';

const FirstStoreTile = () => {
	return(
		<Link to={'/new-store'} 
			className="my-store-tile add-another-store">
			<img src="/images/icons/plus-icon.svg" alt="plus-icon"/>
			<p>Add another store</p>
		</Link>
	)
}

export default FirstStoreTile;