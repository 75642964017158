import { createStore, combineReducers } from 'redux';
import storesReducer from '../reducers/storesReducer';
import userReducer from '../reducers/userReducer';
import loadingReducer from '../reducers/loadingReducer';

export default () => {
    const store = createStore(
        combineReducers({
			storesReducer,
			userReducer,
			loadingReducer
        }),
        window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__() //For the redux chrome extension
    );

    //everytime the store is updated, print it to console
    store.subscribe(()=>{
        // console.log(store.getState());
    });
  
    return store;
};
