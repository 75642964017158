import firebase from 'firebase/app'
import {firebaseConfig} from './config';

import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';

//Initialise firestore
const firebaseApp = firebase.initializeApp(firebaseConfig);

/* Firebase /onauthstatechange used to be here, but to use useHistory it needs to be inside a functional component so its been put into SignOutButton as this seems like the most obvious candidate which remains a constant */

const updateCollection = (collection, doc, data) => {
	return new Promise((resolve, reject) => {
		firebaseApp.firestore().collection(collection).doc(doc).update(data).then(()=>{
			resolve();
		}).catch((error)=>{
			reject(error);
		});
	});	
};

const firebaseCreateDocument = (collection, doc, data) => {
	return new Promise((resolve,reject)=>{
		firebaseApp.firestore().collection(collection).doc(doc).set(data).then(()=>{
			resolve();
		}).catch((error)=>{
			reject(error);
		});
	});
};

const firebaseGetStores = (associatedID) => {
	return new Promise((resolve,reject)=>{
		firebaseApp.firestore().collection("stores").where("associatedID", "==", associatedID).get().then((snapshot)=>{
			resolve(snapshot);
		}).catch((error)=>{
			reject(error);
		});
	});
};

const firebaseAddImage = () => {}

const firebaseDeleteDocument = () => {}

//Look at what Auth persistence we really want. Session or None? Local is for dev
firebaseApp.auth().setPersistence(firebase.auth.Auth.Persistence.NONE).catch(function(error){
	var errorCode = error.code;
	var errorMessage = error.message;
	console.log(errorCode);
	console.log(errorMessage);
});


export {
	firebaseApp,
	updateCollection,
	firebaseGetStores
}
