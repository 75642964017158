import React from 'react';
import {Link} from 'react-router-dom';

const Store = (props) => {
	return(
		<Link className="my-store-tile" to={`/view-store/${props.id}`} key={props.position}>
			<h2>{props.name}</h2>
			<hr className="under-title-rule"/>
			<p>{props.numberOfItems} items</p>
			<p className="address">{props.address.formatted_address}</p>
			<p className="id-value">ID: #{props.id}</p>
			
				{
					props.live ? 
					<div className="live-or-hidden">
						<span class="status live"></span><span>Live</span>
					</div> : 
					<div className="live-or-hidden">
						<span class="status hidden"></span><span>Hidden</span>
					</div> 
				}
			
		</Link>
	)
}

export default Store;