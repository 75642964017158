import React, {useState, useEffect} from 'react';
// import {connect} from 'react-redux';

const LiveSwitch = (props) => {
	// console.log("live switch....");
	const [live, setLive] = useState(props.isLive);
	// console.log(`Live and isLive ${live}, ${props.isLive}`);

	useEffect(()=>{
		setLive(props.isLive);
	}, [props.isLive]);

	return(
		<div className="switch-wrapper">
			<span className={live ? "hidden-label" : "hidden-label active"}>Hidden</span>
			<label className="switch">
				<input 
					type="checkbox"
					onChange={(e)=>{
						setLive(e.target.checked); 
						props.callback(e.target.checked);
					}}
					checked={live}
				/>
				<span className="slider round"></span>
			</label>
		 	<span className={live ? "live-label active" : "live-label"}>Live</span>
		</div>
	);
}

// const mapDispatchToProps = (dispatch) => ({
// 	updateStore : (id, store) => dispatch(updateStore(id, store))
// });

// const mapStateToProps = (state) => {
// 	return {
// 		user : state.userReducer,
// 		stores : state.storesReducer
// 	}
// };

// export default connect(mapStateToProps, mapDispatchToProps)(LiveSwitch);
export default LiveSwitch;