import React, {useState, useEffect} from 'react';
import { connect } from 'react-redux';
// import {SingleDatePicker} from 'react-dates';
// import 'react-dates/initialize';
// import 'react-dates/lib/css/_datepicker.css';
import { addStore, resetStore } from '../actions/storesActions';
import { firebaseGetStores } from '../firebase/firebase';
import FirstStoreTile from './store/tiles/FirstStoreTile';
import AddStore from './store/tiles/AddStore';
import StoreTiles from './store/tiles/StoreTiles';

// export class MyStoreMain extends React.Component {
// 	constructor(props) {
// 		super(props);

// 		this.state = {
// 			user: {},
// 			stores: []
// 		};
// 	}
// 	componentDidUpdate() {
// 		//Keep checking until we have passed props and we havent yet updated state
// 		if (this.props.user.associatedID && !this.state.user.associatedID) {

// 			this.setState({
// 				user: this.props.user
// 			});

// 			firebaseGetStores(props.user.associatedID).then((snapshot) => {
// 				snapshot.docs.forEach(doc => {
// 					let store = doc.data();
// 					console.log(doc.data());

// 					//You have to pull the document id from doc, not doc.data()
// 					store.id = doc.id;

// 					//Tell Redux
// 					props.addStore(store);
// 				})
// 			}).catch((err) => {

// 			});
// 		}
// 	}
// 	render() {
// 		// For each data set in this.props.stores, render this thing...
// 		const storeTiles = this.props.stores.map((items, i) => (
// 			<StoreTiles
// 				key={i}
// 				position={i}
// 				id={items.id}
// 				name={items.name}
// 				address={items.address}
// 				numberOfItems={items.inventory.length}
// 			/>
// 		));

// 		return (
// 			<div>
// 				<div className="my-store">
// 					{
// 						this.state.error ?
// 							<div className="error-wrapper">
// 								<span className="error-message">{this.state.errorMessage}</span>

// 								<button className="error-close" onClick={this.closeError}>
// 									<img src="/images/icons/cross.svg" alt="close" />
// 								</button>
// 							</div> : ''
// 					}

// 					{storeTiles}
// 					{this.props.stores.length === 0 ? <FirstStoreTile /> : ''}
// 					{this.props.user.subscribed && this.props.stores.length > 0 ? <AddStore /> : ""}
// 					{/* <SingleDatePicker/> */}
// 				</div>
// 			</div>

// 		);
// 	}
// }



const MyStoreMain = (props) => {

	document.title = "Peruse | My Stores";

	const [error, setError] = useState({
		isError: false,
		message: ""
	});
	const [user, setUser] = useState(props.user);

	useEffect(()=>{
		if(props.user.associatedID !== user.associatedID){
			setUser(props.user);
			//Get stores from firebase
			firebaseGetStores(props.user.associatedID).then((snapshot) => {
				snapshot.docs.forEach(doc => {
					let store = doc.data();
		
					//You have to pull the document id from doc, not doc.data()
					store.id = doc.id;
		
					//Tell Redux
					props.addStore(store);
				})
			}).catch((err)=>{
				setError({
					isError: true,
					message: err.message
				})
			});
		}
	}, [props.user]);

	const storeTiles = props.stores.map((items, i) => (
		<StoreTiles
			key={i}
			position={i}
			id={items.id}
			live={items.live}
			name={items.name}
			address={items.address}
			numberOfItems={items.inventory.length}
		/>
	));

	return (
		<div>
			<div className="my-store">
				{
					error.isError ?
						<div className="error-wrapper">
							<span className="error-message">
								{error.message}
							</span>

							<button 
								className="error-close" 
								onClick={closeError}>
								<img src="/images/icons/cross.svg" 
									alt="close"/>
							</button>
						</div> : ''
				}

				{storeTiles}
				{props.stores.length === 0 ? <FirstStoreTile /> : ''}
				{props.stores.length > 0 ? <AddStore /> : ""}
				{/* <SingleDatePicker/> */}
			</div>
		</div>
	);
}

/*
  This is used with connect()() to basically say
  Give the application MyStoreMain the properties mapDispatchToProps
  giving MyStoreMain the ability to say this.props.dispatch
  and then send something to the reducer

  Same for mapStateToProps, it's just saying let the component use
  this.props.stores which is data from the ReduxStore
*/
const mapDispatchToProps = (dispatch) => ({
	addStore: (store) => dispatch(addStore(store)),
	resetStore: () => dispatch(resetStore())
});

const mapStateToProps = (state) => {
	return {
		user: state.userReducer,
		stores: state.storesReducer
	}
};

export default connect(mapStateToProps, mapDispatchToProps)(MyStoreMain);