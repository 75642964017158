import React, {useState} from 'react';
import validator from 'validator';

const ChangeEmail = (props) => {
	const [newEmail, setNewEmail] = useState("");
	const [confirmNewEmail, setConfirmNewEmail] = useState("");
	const [error, setError] = useState(false);
	const [errorMessage, setErrorMessage] = useState("");

	// componentDidMount() {
	// 	this.setState(() => ({
	// 		currentEmail: this.props.email
	// 	}));
	// }
	
	const submitNewEmail = () => {
		setError(false);

		if (newEmail !== confirmEmail) {
			setError(true);
			setErrorMessage("Emails do not match");
		}
		else if (!validator.isEmail(newEmail)) {
			setError(true);
			setErrorMessage("Not a valid email");
		}
		else {
			setError(false);
			props.callback(newEmail);
		}
	}

	return (
		<div className="change-email-wrapper">
			<h3>Change Email Address</h3>

			{
				error ? <span className="input-error">{errorMessage}</span> : ''
			}

			<label htmlFor="email">Current Email Address</label>
			<p>{props.email}</p>

			<label htmlFor="email">New Email Address</label>
			<input type="text"
				name="email"
				className="text-input"
				value={newEmail}
				onChange={(e)=>setNewEmail(e.target.value)}
				placeholder="yournewemail@email.com"
			/>

			<label htmlFor="email-confirm">Confirm New Email</label>
			<input type="text"
				name="email-confirm"
				className="text-input"
				value={confirmNewEmail}
				onChange={(e)=>setConfirmNewEmail(e.target.value)}
				placeholder="yournewemail@email.com"
			/>

			<button 
				onClick={submitNewEmail} 
				className="button save">
					Save My Details
			</button>
		</div>
	);
}

export default ChangeEmail;

