import React, {useState} from 'react';

const ChangePassword = (props) => {
	const [currentPassword, setCurrentPassword] = useState("");
	const [newPassword, setNewPassword] = useState("");
	const [confirmNewPassword, setConfirmNewPassword] = useState("");

	const [error, setError] = useState(false);
	const [errorMessage, setErrorMessage] = useState("");

	const resetPassword = () => {	
		setError(false);
		if(newPassword !== confirmNewPassword){
			setError(true);
			setErrorMessage("Passwords do not match");
		}
		else if(newPassword.length < 6){
			setError(true);
			setErrorMessage("Password needs to be longer than 6 characters");
		}
		else if(!newPassword){
			setError(true);
			setErrorMessage("You must enter data into all fields");
		}
		else if(!newPasswordConfirmed){
			setError(true);
			setErrorMessage("You must enter data into all fields");
		}
		else{
			props.callback(newPassword);
		}
	}


	return (
		<div className="change-password-wrapper">

			<h3>Change Password</h3>

			{
				error ?
					<span>{errorMessage}</span>
					: ''
			}

			<label htmlFor="email">Your Current Password</label>
			<input type="text"
				name="email"
				className="text-input"
				value={currentPassword}
				onChange={e=>setCurrentPassword(e.target.value)} />

			<label htmlFor="email">Your New Password</label>
			<input type="text"
				name="email"
				className="text-input"
				value={newPassword}
				onChange={e=>setNewPassword(e.target.value)}/>

			<label htmlFor="email">Confirm New Password</label>
			<input type="text"
				name="email"
				className="text-input"
				value={confirmNewPassword}
				onChange={e=>setConfirmNewPassword(e.target.value)}/>

			<button 
				onClick={resetPassword} 
				className="button save">
					Change Password
			</button>
		</div>
	);
}

export default ChangePassword;

