import React from 'react'
import {Link} from 'react-router-dom';

import {firebaseApp} from '../../firebase/firebase';
import {useHistory} from 'react-router';

const SignOutButton = () => {
	const history = useHistory();

	firebaseApp.auth().onAuthStateChanged(function(user) {
		if (user) {
			console.log(user);
			// User is signed in.
			console.log("We've got a user");
			history.push('/my-stores');
		} else {
			// No user is signed in.
			history.push('/signin');
			console.log("No user :(");
		}
	});

	const signOut = (e) => {
		e.preventDefault();
		firebaseApp.auth().signOut().then(()=>{
			history.push('/');
		}).catch((error)=>{
			// An error happened.
		});
	}

	return(
		<div className="logout">
			<Link to="/" onClick={signOut}>
				<img src="/images/icons/sign-out-icon.svg" alt="sign out icon"/>
					Sign Out
			</Link>
		</div>
	);
}

export default SignOutButton;